import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { __Post } from "../lib/Rest";
import { Box, Paper } from "@material-ui/core";
import { Stream } from "../lib/Types";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {
   data: Array<any>;
}

class Stats extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         data: []
      };
   }

   componentDidMount() {
      this.loadStats();
   }

   componentWillUnmount() {}

   loadStats() {
      __Post(
         "stats",
         { event: this.props.currentStream.event },
         (res: any) => {
            this.setState({ data: res });
            console.log(res);
         },
         (fail: any) => {}
      );
   }

   render() {
      const { classes, history } = this.props;
      const { data } = this.state;

      return (
         <React.Fragment>
            <div className={classes.root}>
               {data.map((ac) => {
                  return (
                     <Paper>
                        <h4>
                           {ac.anrede +
                              " " +
                              ac.titel +
                              " " +
                              ac.vorname +
                              " " +
                              ac.name}
                        </h4>
                        {ac.logins.length === 0 ? (
                           <Box color="text.secondary">
                              <>kein LOGIN</>
                           </Box>
                        ) : (
                           <Box color="text.primary">
                              {ac.logins.map((l: any) => {
                                 return <p>Login : {l.time}</p>;
                              })}
                           </Box>
                        )}

                        <Box>
                           {ac.feedback.map((f: any) => {
                              return (
                                 <p>
                                    Feedback ({f.time}) : {f.message}
                                 </p>
                              );
                           })}
                        </Box>
                     </Paper>
                  );
               })}
            </div>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(Stats));
