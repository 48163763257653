import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "./../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   Button,
   Grid,
   Grow,
   Paper,
   Slide,
   Typography
} from "@material-ui/core";
import { Stream } from "../lib/Types";
import { i18n } from "../lib/i18n";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      headerImg: {
         width: "100%",
         objectFit: "contain"
      },
      marginAutoItem: {
         margin: "auto"
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {
   acceptedDSGVO: boolean;
}

class DSGVO extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         acceptedDSGVO: !props.currentStream.showDSGVO
      };
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      const { classes, history, currentStream, children } = this.props;
      if (this.state.acceptedDSGVO) {
         return (
            <React.Fragment>
               <React.Fragment>{children}</React.Fragment>
            </React.Fragment>
         );
      } else {
         return (
            <React.Fragment>
               <Grid
                  container
                  className={classes.root}
                  spacing={3}
                  justifyContent={"center"}
                  alignContent={"center"}>
                  <Grid item xs={12}>
                     <Paper className={classes.root}>
                        <Typography variant="body2" component="p">
                           <span
                              className="agra-title-color"
                              dangerouslySetInnerHTML={{
                                 __html: i18n.get(
                                    "DSGVO_" + currentStream.event
                                 )
                              }}></span>
                        </Typography>
                     </Paper>
                  </Grid>
                  <Grid item className={classes.marginAutoItem}>
                     <Button
                        variant={"contained"}
                        color="primary"
                        size={"large"}
                        onClick={() => {
                           this.setState({ acceptedDSGVO: true });
                        }}>
                        {i18n.get("dsgvoAcceptButton")}
                     </Button>
                  </Grid>
               </Grid>
            </React.Fragment>
         );
      }
   }
}

export default withStyles(styles)(withRouter(DSGVO));
