import { Helper } from "./Helper";
import { funcFail, funcSuccess } from "./Types";

const __Get = function (
   method: string,
   onSuccess: funcSuccess,
   onFail: funcFail
) {
   fetch(Helper.baseUrl + method + ".php", {
      method: "GET",
      //mode: "no-cors",
      headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
         "Cache-Control": "no-cache, no-store, must-revalidate",
         Pragma: "no-cache",
         Expires: "0"
      }
   })
      .then((res) => res.json())
      .then((res) => {
         if (onSuccess !== undefined) {
            onSuccess(res);
         }
      })
      .catch((res) => {
         if (onFail !== undefined) {
            onFail(res);
         }
      });
};

const __Post = function (
   method: string,
   data: any,
   onSuccess: funcSuccess = (res: any) => {},
   onFail: funcFail = (res: any) => {}
) {
   fetch(Helper.baseUrl + method + ".php", {
      method: "POST",
      //mode: "no-cors",
      body: JSON.stringify(data !== undefined ? data : {}),
      headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
         "Cache-Control": "no-cache, no-store, must-revalidate",
         Pragma: "no-cache",
         Expires: "0"
      }
   })
      .then((res) => res.json())
      .then((res) => {
         if (onSuccess !== undefined) {
            onSuccess(res);
         }
      })
      .catch((res) => {
         if (onFail !== undefined) {
            onFail(res);
         }
      });
};
export { __Post, __Get };
