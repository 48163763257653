import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   Button,
   Card,
   CardActions,
   CardContent,
   Collapse,
   IconButton,
   TextField,
   Typography
} from "@material-ui/core";
import Session from "../lib/Session";
import { __Post } from "../lib/Rest";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { i18n } from "../lib/i18n";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(0),
         marginBottom: theme.spacing(2)
      },
      field: {
         width: "100%"
      },
      buttons: {
         padding: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   eventName: string;
   defaultProvenQuality: string;
   cancel: () => void;
   onUpdate: () => void;
   maxChars?: number;
   important: boolean;
}

interface IStates {
   text: string;
   sending: boolean;
   showAlert: boolean;
}

class FeedbackCommit extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         text: "",
         sending: false,
         showAlert: false
      };
   }

   shouldComponentUpdate(nextProps: IProps, nextState: IStates) {
      if (nextProps.eventName !== this.props.eventName) {
         return true;
      }
      return true;
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      const { classes, eventName, maxChars = 300, important } = this.props;

      return (
         <React.Fragment>
            <Card className={classes.root}>
               <CardContent>
                  <TextField
                     rows={4}
                     className={classes.field}
                     id="outlined-textarea"
                     label={i18n.get("Kommentar / Frage")}
                     placeholder={i18n.get("Kommentar / Frage")}
                     multiline
                     variant="outlined"
                     value={this.state.text}
                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        let v = e.target.value;
                        v = v.substr(0, maxChars);
                        this.setState({ text: v });
                     }}
                  />
                  <Typography variant="caption">
                     {this.state.text.length} von {maxChars}
                  </Typography>
               </CardContent>
               <CardActions className={classes.buttons}>
                  <Button
                     disabled={this.state.sending || this.state.text.length < 2}
                     variant="contained"
                     color="primary"
                     onClick={() => {
                        let data: any = {};
                        if (Session.getInstance().hasValidUser()) {
                           data.userId = Session.getInstance().getUser().id;
                           data.user = Session.getInstance().getUserName();
                        } else {
                           data.userId = important ? 2 : 1;
                           data.user = important ? "Anonymous" : "Admin";
                        }
                        data.message = this.state.text;
                        data.event = eventName;
                        data.state = this.props.defaultProvenQuality;

                        this.setState({ sending: true }, () => {
                           __Post(
                              "appendFeedback",
                              data,
                              (res: any) => {
                                 this.setState({ sending: false }, () => {
                                    this.setState({ showAlert: true }, () => {
                                       setTimeout(() => {
                                          this.setState({
                                             showAlert: false,
                                             text: ""
                                          });
                                          this.props.onUpdate();
                                       }, 2000);
                                    });
                                 });
                              },
                              (fail: any) => {
                                 this.setState({ sending: false });
                              }
                           );
                        });
                     }}>
                     {i18n.get("Senden")}
                  </Button>
                  <Button
                     onClick={() => this.props.cancel()}
                     variant="contained">
                     {i18n.get("Abbrechen")}
                  </Button>
               </CardActions>
               <CardContent>
                  <Collapse in={this.state.showAlert} className={classes.field}>
                     <Alert
                        className={classes.field}
                        severity="success"
                        action={
                           <IconButton
                              aria-label="close"
                              color="inherit"
                              size="small"
                              onClick={() => {
                                 this.setState({ showAlert: false });
                              }}>
                              <CloseIcon fontSize="inherit" />
                           </IconButton>
                        }>
                        {i18n.get("Erfolgreich übertragen")}
                     </Alert>
                  </Collapse>
               </CardContent>
            </Card>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(FeedbackCommit));
