import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { Stream } from "../lib/Types";
import Chart from "react-apexcharts";
import { __Post } from "../lib/Rest";
import moment from "moment";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2),
         backgroundColor: "#000"
      },
      headline: {
         color: "#fff"
      },
      time: {
         color: "#fff",
         fontSize: 250,
         fontWeight: 800
      },
      verlauf: {
         width: "100%",
         height: "150px",
         display: "flex",
         alignItems: "flex-end",
         flexDirection: "row",
         flexWrap: "nowrap",
         justifyContent: "flex-start"
      },
      svg: {
         backgroundColor: "#000"
      },
      normal: {
         fill: "url(#color)",
         stroke: "rgba(155,155,155,1)",
         strokeWidth: "2px",
         fillRule: "evenodd"
      },
      fill0: {
         stopColor: "rgb(0,255,0)",
         stopOpacity: 1
      },
      fill50: {
         stopColor: "rgb(100,255,0)",
         stopOpacity: 1
      },
      fill100: {
         stopColor: "rgb(255,255,0)",
         stopOpacity: 1
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {
   currentListener: number;
   time: string;
   options: any;
   series: Array<any>;
}

class AdmClientStats extends BaseComponent<IProps, IStates> {
   interval: any;
   interval2: any;

   constructor(props: IProps) {
      super(props);
      this.state = {
         currentListener: 0,
         time: "",
         options: {},
         series: []
      };
   }

   componentDidMount() {
      this.getDataLine();
      this.interval = setInterval(() => {
         this.getDataLine();
      }, 30 * 1000);
      this.interval2 = setInterval(() => {
         this.setState({ time: new Date().toLocaleTimeString() });
      }, 1000);
   }

   getDataLine() {
      __Post(
         "viewStats",
         {
            event: this.props.currentStream.event,
            from: moment().add(-60, "minutes").format("YYYY-MM-DD HH:mm:ss"),
            until: moment().format("YYYY-MM-DD HH:mm:ss")
         },

         (res: any) => {
            let op = {
               grid: {
                  show: true,
                  borderColor: "#333344",
                  strokeDashArray: 0,
                  position: "back",
                  xaxis: {
                     lines: {
                        show: false
                     }
                  },
                  yaxis: {
                     lines: {
                        show: true
                     }
                  },
                  row: {
                     colors: ["#0000ff"],
                     opacity: 0.1
                  },
                  padding: {
                     top: 0,
                     right: 0,
                     bottom: 0,
                     left: 10
                  }
               },
               legend: {
                  show: true
               },
               chart: {
                  type: "line",
                  foreColor: "#ffffff",
                  animations: {
                     enabled: true,
                     easing: "easeinout",
                     speed: 800,
                     animateGradually: {
                        enabled: true,
                        delay: 150
                     },
                     dynamicAnimation: {
                        enabled: true,
                        speed: 350
                     }
                  }
               },
               stroke: {
                  curve: "smooth"
               },
               yaxis: {
                  show: true,
                  showAlways: true,
                  labels: {
                     show: true,
                     style: {
                        colors: [],
                        fontSize: "20px"
                     }
                  }
               },
               xaxis: {
                  type: "category",
                  tickPlacement: "between",
                  labels: {
                     show: false,
                     hideOverlappingLabels: true
                  }
               }
            };

            let data = new Array<any>();
            res.map((r: any) => {
               data.push(r.users);
            });

            let series = [
               {
                  name: "CurrentUsers",
                  data: data
               }
            ];
            this.setState({
               currentListener: res[res.length - 1].users,
               options: op,
               series: series
            });
         }
      );
   }

   componentWillUnmount() {
      clearInterval(this.interval);
      clearInterval(this.interval2);
   }

   render() {
      const { classes, currentStream } = this.props;

      return (
         <React.Fragment>
            <Helmet>
               <title>
                  {this.state.currentListener + " - " + currentStream.event}
               </title>
               <style>{"body { background-color: black; }"}</style>
            </Helmet>

            <Typography
               variant={"h1"}
               className={classes.headline}
               align="center">
               CurrentUsers: <strong>{this.state.currentListener}</strong> -{" "}
               <strong>{this.state.time}</strong>
            </Typography>
            <Chart
               options={this.state.options}
               series={this.state.series}
               type="line"
               height={"700"}
            />
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(AdmClientStats));
