import React from "react";

/**
 * extends React.Component with a hashCode function
 *
 * @author <a href="mailto:rene.dietze@byte-island.com">Rene Dietze</a>
 */
export default class BaseComponent<PROPS, STATES> extends React.Component<
   PROPS,
   STATES
> {
   private static c: number = 1024;
   private _UID: string | undefined = undefined;

   getHashCode(): string {
      if (this._UID === undefined) {
         BaseComponent.c++;
         this._UID = Math.random().toString(16).substr(2) + BaseComponent.c;
      }
      return this._UID;
   }
}
