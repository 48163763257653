import Session from "./Session";

let __sendinblue__ = false;
export const sendinblue = function () {
   if (__sendinblue__) {
      if (Session.getInstance().hasValidUser()) {
         if (window.sib !== undefined) {
            window.sib.email_id = Session.getInstance().getUser().email;
         }
         if (
            window.sendinblue !== undefined &&
            window.sendinblue.identify !== undefined
         ) {
            window.sendinblue.identify(Session.getInstance().getUser().email, {
               FIRSTNAME: Session.getInstance().getUser().vorname,
               LASTNAME: Session.getInstance().getUser().name
            });
         }
      }
      return;
   }
   window.sib = { equeue: [], client_key: "dfjeyxf06i7v91gabm7bg3dz" };
   window.sib.display_chat = 1;
   window.sib.display_logo = 1;
   window.sib.custom_welcome_message = "Hello, how can we help you?";
   window.sib.custom_offline_message =
      "We are currently offline. In order to answer you, please indicate your email in your messages.";
   window.sendinblue = {};
   for (
      let j = ["track", "identify", "trackLink", "page"], i = 0;
      i < j.length;
      i++
   ) {
      (function (k) {
         window.sendinblue[k] = function () {
            let arg = Array.prototype.slice.call(arguments);
            (
               window.sib[k] ||
               function () {
                  let t = {};
                  t[k] = arg;
                  window.sib.equeue.push(t);
               }
            )(arg[0], arg[1], arg[2]);
         };
      })(j[i]);
   }
   let n = document.createElement("script");
   let i = document.getElementsByTagName("script")[0];

   n.type = "text/javascript";
   n.id = "sendinblue-js";
   n.async = !0;
   n.src = "https://sibautomation.com/sa.js?key=" + window.sib.client_key;
   i.parentNode.insertBefore(n, i);
   window.sendinblue.page();

   __sendinblue__ = true;
};
