import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "./../lib/BaseComponent";
import { Paper } from "@material-ui/core";
import { Stream } from "./../lib/Types";
import WSEventHandler, { IWSEventHandler } from "../lib/WSEventHandler";

const styles = (theme: Theme) =>
   createStyles({
      fullHDAspect: {
         position: "relative",
         paddingBottom: "56.25%",
         height: 0,
         overflow: "hidden"
      },
      video: {
         position: "absolute",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         border: "none"
      }
   });

interface IProps extends WithStyles<typeof styles> {
   stream: Stream;
}

interface IStates {
   overwriteURL: string | undefined;
}

class StreamPlayer
   extends BaseComponent<IProps, IStates>
   implements IWSEventHandler
{
   constructor(props: IProps) {
      super(props);
      this.state = {
         overwriteURL: undefined
      };
   }

   shouldComponentUpdate(nextProps: IProps, nextState: IStates) {
      if (nextProps.stream.id !== this.props.stream.id) {
         return true;
      }
      if (nextState.overwriteURL !== this.state.overwriteURL) {
         return true;
      }
      return false;
   }

   getWSNamespaces() {
      return ["setURL", "reload"];
   }

   onWSMessage(cmd: string, msg: string) {
      if (cmd === "setURL") {
         if (msg === "NULL") {
            this.setState({ overwriteURL: undefined });
         } else {
            this.setState({ overwriteURL: msg });
         }
      }
      if (cmd === "reload") {
         if (msg === "iframe") {
            this.forceUpdate();
         }
      }
   }

   onWSDisconnect() {}

   onWSisAlive() {}

   onWSConnect() {}

   componentDidMount() {
      WSEventHandler.getInstance().addEventHandler(super.getHashCode(), this);
   }

   componentWillUnmount() {
      WSEventHandler.getInstance().removeEventHandler(super.getHashCode());
   }

   render() {
      const { classes, stream } = this.props;

      if (this.state.overwriteURL !== undefined) {
         return (
            <Paper
               style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden"
               }}
               className={classes.fullHDAspect}>
               <iframe
                  style={{
                     position: "absolute",
                     top: 0,
                     left: 0,
                     width: "100%",
                     height: "100%",
                     border: "none"
                  }}
                  title={"Stream"}
                  src={this.state.overwriteURL}
                  className={classes.video}
                  data-allowfullscreen="allowfullscreen"
                  allowFullScreen
               />
            </Paper>
         );
      } else {
         return (
            <Paper
               style={{
                  position: "relative",
                  paddingBottom: "56.25%",
                  height: 0,
                  overflow: "hidden"
               }}
               className={classes.fullHDAspect}>
               <iframe
                  style={{
                     position: "absolute",
                     top: 0,
                     left: 0,
                     width: "100%",
                     height: "100%",
                     border: "none"
                  }}
                  title={stream.title}
                  src={stream.url}
                  className={classes.video}
                  data-allowfullscreen="allowfullscreen"
                  allowFullScreen
               />
            </Paper>
         );
      }
   }
}
export default withStyles(styles)(StreamPlayer);
