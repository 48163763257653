import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, ButtonGroup } from "@material-ui/core";
import { __Post } from "../lib/Rest";
import { Stream } from "../lib/Types";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
   buttons: Array<any>;
}

interface IStates {}

class AdmFeedbackButtons extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {};
   }

   shouldComponentUpdate(nextProps: IProps, nextState: IStates) {
      if (nextProps.currentStream.event !== this.props.currentStream.event) {
         return true;
      }
      return false;
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      return (
         <React.Fragment>
            <ButtonGroup
               variant="contained"
               color="primary"
               aria-label="contained primary button group">
               {this.props.buttons.map((b) => {
                  return (
                     <Button
                        size="large"
                        onClick={() => {
                           if (window.confirm(b.s + " all, Are you sure?"))
                              __Post("updateAll", {
                                 event: this.props.currentStream.event,
                                 provenQuality: b.s
                              });
                        }}
                        color={b.color}>
                        {b.title}
                     </Button>
                  );
               })}
            </ButtonGroup>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(AdmFeedbackButtons));
