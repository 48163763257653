import WSEventHandler from "./WSEventHandler";
import { Helper } from "./Helper";

export default class WebSock {
   private interval: any = undefined;
   private con: any = undefined;
   private event: string = "";

   public constructor(eventName: string) {
      this.event = eventName;
      this.interval = setInterval(() => {
         this.checkConnection();
      }, 20 * 1000);
   }

   public checkConnection() {
      console.log("checkConnection", this.event);
      if (this.con === undefined) {
         this.connect();
         return;
      } else if (this.con.readyState !== WebSocket.OPEN) {
         this.connect();
         return;
      } else {
         WSEventHandler.getInstance().isAlive();
         this.sendMessage("ping::keepAlive");
      }
   }

   public sendMessage(msg: string) {
      try {
         if (
            (this.con === undefined && this.con.readyState) !== WebSocket.OPEN
         ) {
            console.log("WSSock send: (" + this.event + ")", this.event, msg);
            this.con.send(msg);
         }
      } catch (ex) {
         console.error(ex);
      }
   }

   getClientInfo() {
      return (
         "TZ:" +
         new Date().getTimezoneOffset() / 60 +
         " [" +
         navigator.language +
         "] : " +
         window.location.href +
         " -> (" +
         navigator.userAgent +
         ")"
      );
   }

   public disconnect() {
      if (this.interval !== undefined) {
         clearInterval(this.interval);
      }
      try {
         if (
            (this.con === undefined && this.con.readyState) !== WebSocket.OPEN
         ) {
            this.con.close();
         }
      } catch (ex) {
         console.error(ex);
      }
   }

   public connect() {
      if (this.con !== undefined) {
         WSEventHandler.getInstance().onDisconnect();
      }
      this.con = new WebSocket(Helper.webSocket);
      this.con.onopen = () => {
         WSEventHandler.getInstance().onConnect();
         this.sendMessage("init::" + this.event);
         this.sendMessage("clientInfo::" + this.getClientInfo());
         this.sendMessage("getLastClientStates::all");
      };
      this.con.onerror = (error: any) => {
         console.log(this.event, "ERROR", error);
      };
      this.con.onclose = (error: any) => {
         console.log(this.event, "CLOSE", error);
         WSEventHandler.getInstance().onDisconnect();
      };

      this.con.onmessage = (e: any) => {
         console.log("onWSMessage (" + this.event + ")", e.data);
         let parts = e.data.split("::");
         if (parts.length === 3) {
            if (parts[0] === "rcvClient") {
               WSEventHandler.getInstance().onMessage(parts[1], parts[2]);
            }
         }
         if (parts.length === 2) {
            WSEventHandler.getInstance().onMessage(parts[0], parts[1]);
         }
      };
   }

   private destroy() {
      clearInterval(this.interval);
   }
}
