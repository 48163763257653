import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   Box,
   Card,
   Collapse,
   Grid,
   IconButton,
   Typography
} from "@material-ui/core";
import FeedbackCommit from "./FeedbackCommit";
import FeedbackList from "./FeedbackList";
import { Stream } from "../lib/Types";
import WSEventHandler, { IWSEventHandler } from "../lib/WSEventHandler";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ChatIcon from "@material-ui/icons/Chat";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Poll from "./Poll";
import { i18n } from "../lib/i18n";
import Session from "../lib/Session";
import AnnoLogin from "./AnnoLogin";
import PasswordLogin from "./PasswordLogin";
import Login from "./Login";
import Disclaimer from "./Disclaimer";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      feedbackView: {
         maxHeight: "70vh",
         overflowY: "scroll",
         overflowX: "hidden"
      },
      fontFix: {
         color: theme.palette.text.primary
      },
      logon: {
         backgroundColor: "#0077b6",
         color: "#fff"
      },
      feedbackCommit: {}
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
   ip: string;
   important?: boolean;
   showfeedbacks?: boolean;
   forceLogin?: boolean;
}

interface IStates {
   writeComment: boolean;
   interactiveType: string;
}

class FeedbackMod
   extends BaseComponent<IProps, IStates>
   implements IWSEventHandler
{
   constructor(props: IProps) {
      super(props);
      this.state = { writeComment: false, interactiveType: "links" };
   }

   componentDidMount() {
      WSEventHandler.getInstance().addEventHandler(super.getHashCode(), this);
   }

   componentWillUnmount() {
      WSEventHandler.getInstance().removeEventHandler(super.getHashCode());
   }

   getWSNamespaces() {
      return ["interactiveType"];
   }

   onWSMessage(cmd: string, msg: string) {
      if (cmd === "interactiveType") {
         this.setState({ interactiveType: msg });
      }
   }

   onWSDisconnect() {}

   onWSisAlive() {}

   onWSConnect() {}

   render() {
      const {
         classes,
         ip,
         currentStream,
         important = false,
         showfeedbacks = true,
         forceLogin = false
      } = this.props;
      const { writeComment } = this.state;
      return (
         <React.Fragment>
            {this.state.interactiveType.startsWith("<") ? (
               <>
                  <div
                     dangerouslySetInnerHTML={{
                        __html: this.state.interactiveType
                     }}></div>
                  <hr />
               </>
            ) : (
               <></>
            )}

            {this.state.interactiveType.startsWith("POLL:") ? (
               <>
                  <Poll
                     id={parseInt(this.state.interactiveType.split(":")[1])}
                     currentStream={this.props.currentStream}
                  />
               </>
            ) : (
               ""
            )}
            {this.state.interactiveType.startsWith("DISCLAIMER") ? (
               <>
                  <Disclaimer
                     link={this.state.interactiveType.split("|")[1]}
                     currentStream={this.props.currentStream}
                  />
               </>
            ) : (
               ""
            )}

            {this.state.interactiveType.startsWith("SHOW_BY_INSTITUTION:") ? (
               <div
                  dangerouslySetInnerHTML={{
                     __html: i18n.get(
                        "SHOW_BY_INSTITUTION_" +
                           currentStream.event +
                           "_" +
                           this.state.interactiveType.split(":")[1] +
                           "_" +
                           Session.getInstance().getUser().institution
                     )
                  }}></div>
            ) : (
               ""
            )}

            {forceLogin &&
            !Session.getInstance().hasValidUser() &&
            !Session.getInstance().hasValidEventUser(currentStream.event) ? (
               <React.Fragment>
                  <Card className={classes.logon}>
                     <Collapse in={writeComment}>
                        <Grid
                           container
                           direction="row"
                           justifyContent="flex-start"
                           alignItems="center">
                           <Grid item>
                              <IconButton
                                 onClick={() => {
                                    this.setState({ writeComment: false });
                                 }}>
                                 <CloseIcon style={{ color: "#fff" }} />
                              </IconButton>
                           </Grid>
                           <Grid item>
                              <Typography variant="h6">
                                 {i18n.get("Schließen")}
                              </Typography>
                           </Grid>
                        </Grid>

                        <Box className={classes.feedbackCommit}>
                           <AnnoLogin
                              checkPassword={true}
                              currentStream={currentStream}
                              onSuccess={() => this.forceUpdate()}
                           />
                        </Box>
                     </Collapse>
                     <Collapse in={!writeComment}>
                        <Grid
                           container
                           direction="row"
                           justify="flex-end"
                           alignItems="flex-start">
                           <Grid item xs={12}>
                              <Grid
                                 onClick={() => {
                                    this.setState({ writeComment: true });
                                 }}
                                 container
                                 direction="row"
                                 justifyContent="flex-start"
                                 alignItems="center">
                                 <Grid item>
                                    <IconButton
                                       onClick={() => {
                                          this.setState({ writeComment: true });
                                       }}>
                                       <LockOpenIcon
                                          style={{ color: "#fff" }}
                                       />
                                    </IconButton>
                                 </Grid>
                                 <Grid item>
                                    <Typography variant="h6">
                                       {i18n.get("Beim Feedbackmodul Anmelden")}
                                    </Typography>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Collapse>
                  </Card>
               </React.Fragment>
            ) : (
               <React.Fragment>
                  <Collapse in={writeComment}>
                     <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Grid item>
                           <IconButton
                              onClick={() => {
                                 this.setState({ writeComment: false });
                              }}>
                              <CloseIcon />
                           </IconButton>
                        </Grid>
                        <Grid item>
                           <Typography variant="body1">
                              {i18n.get("Schließen")}
                           </Typography>
                        </Grid>
                     </Grid>

                     <Box className={classes.feedbackCommit}>
                        <FeedbackCommit
                           important={important}
                           cancel={() => this.setState({ writeComment: false })}
                           onUpdate={() => {
                              this.setState({ writeComment: false });
                              WSEventHandler.getInstance().sendMessage(
                                 "toClient::feedback::doReload"
                              );
                           }}
                           eventName={currentStream.event}
                           defaultProvenQuality={
                              currentStream.defaultProvenQuality
                           }
                        />
                     </Box>
                  </Collapse>
                  <Collapse in={!writeComment}>
                     <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="flex-start">
                        <Grid item xs={12}>
                           <Grid
                              container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center">
                              <Grid item>
                                 <IconButton
                                    onClick={() => {
                                       this.setState({ writeComment: true });
                                    }}>
                                    <AddCircleOutlineOutlinedIcon />
                                 </IconButton>
                              </Grid>
                              <Grid item>
                                 <Typography variant="body1">
                                    {i18n.get("Frage stellen")}
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Collapse>
               </React.Fragment>
            )}

            {currentStream.showFeedbacks && showfeedbacks ? (
               <Box className={classes.feedbackView}>
                  <FeedbackList
                     ip={ip}
                     eventName={
                        currentStream.feedbackListByRootStream
                           ? currentStream.rootEvent + "%"
                           : currentStream.event
                     }
                     onlyMyself={currentStream.onlyMyself}
                     filter={currentStream.streamFilter}
                     likeable={currentStream.likeable}
                     buttons={[]}
                     loadDiffs={true}
                     reloadTime={currentStream.feedbackReloadInterval}
                  />
               </Box>
            ) : (
               <></>
            )}
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(FeedbackMod));
