import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Button, Fade, Paper, Typography } from "@material-ui/core";
import { __Post } from "../lib/Rest";
import moment from "moment";
import WSEventHandler, { IWSEventHandler } from "../lib/WSEventHandler";
import { Stream } from "../lib/Types";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
   showAdminButtons?: boolean;
   fullAgenda: boolean;
}

interface IStates {
   currentAgenda: number;
   agenda: Array<any>;
   show: boolean;
}

class Agenda extends BaseComponent<IProps, IStates> implements IWSEventHandler {
   constructor(props: IProps) {
      super(props);
      this.state = {
         agenda: [],
         show: true,
         currentAgenda: -1
      };
   }
   componentDidMount() {
      this.loadAgenda();

      WSEventHandler.getInstance().addEventHandler(super.getHashCode(), this);
   }
   componentWillUnmount() {
      WSEventHandler.getInstance().removeEventHandler(super.getHashCode());
   }

   getWSNamespaces() {
      return ["agenda"];
   }

   onWSMessage(cmd: string, msg: string) {
      if (cmd === "agenda") {
         if (!this.props.fullAgenda) {
            this.setState({ currentAgenda: parseInt(msg) });
         }
      }
   }

   onWSDisconnect() {}

   onWSisAlive() {}

   onWSConnect() {}

   loadAgenda() {
      __Post(
         "getAgenda",
         { event: this.props.currentStream.event },
         (res: any) => {
            this.setState({ agenda: res });
         }
      );
   }

   /*
   shouldComponentUpdate(nextProps: IProps, nextState: IStates) {
      if (nextProps.currentStream.event !== this.props.currentStream.event) {
         this.setState({ agenda: [] }, () => {
            this.loadAgenda();
         });
      }
      if (nextProps.currentAgenda !== this.props.currentAgenda) {
         return true;
      }
      if (nextState.agenda !== this.state.agenda) {
         return true;
      }
      if (nextState.show !== this.state.show) {
         return true;
      }
      return false;
   }
*/
   render() {
      const { classes, currentStream, showAdminButtons = false } = this.props;
      const showAgendaTimes: boolean = currentStream.showAgendaTimes;

      if (this.state.agenda.length === 0) {
         return <React.Fragment></React.Fragment>;
      }

      if (showAdminButtons || this.state.currentAgenda > 0) {
         return (
            <React.Fragment>
               <Paper className={classes.root}>
                  {this.state.agenda.map((a) => {
                     if (showAdminButtons) {
                        return (
                           <div key={a.id}>
                              {showAgendaTimes ? (
                                 <Typography variant="h5" component="h2">
                                    {moment(a.time).format("HH:mm")}
                                 </Typography>
                              ) : (
                                 <></>
                              )}
                              <Typography variant="body2" component="p">
                                 <span
                                    className="agra-title-color"
                                    dangerouslySetInnerHTML={{
                                       __html: a.description
                                    }}></span>
                              </Typography>
                              <Button
                                 variant="contained"
                                 onClick={() => {
                                    WSEventHandler.getInstance().sendMessage(
                                       "toClient::agenda::" + a.id
                                    );
                                 }}
                                 color={
                                    parseInt(a.id) === this.state.currentAgenda
                                       ? "primary"
                                       : "default"
                                 }>
                                 Aktiv
                              </Button>
                              <hr />
                           </div>
                        );
                     } else if (a.id >= this.state.currentAgenda) {
                        return (
                           <Fade key={a.id} in={this.state.show}>
                              <div>
                                 {showAgendaTimes ? (
                                    <Typography variant="h5" component="h2">
                                       {moment(a.time).format("HH:mm")}
                                    </Typography>
                                 ) : (
                                    <></>
                                 )}
                                 <Typography variant="body2" component="p">
                                    <span
                                       className="agra-title-color"
                                       dangerouslySetInnerHTML={{
                                          __html: a.description
                                       }}></span>
                                 </Typography>
                              </div>
                           </Fade>
                        );
                     } else {
                        return "";
                     }
                  })}
               </Paper>
            </React.Fragment>
         );
      } else {
         return (
            <React.Fragment>
               <Paper className={classes.root}>
                  <h1>{showAgendaTimes}</h1>
                  {this.state.agenda.map((a) => {
                     return (
                        <div key={a.id}>
                           {showAgendaTimes ? (
                              <Typography variant="h5" component="h2">
                                 {moment(a.time).format("HH:mm")}
                              </Typography>
                           ) : (
                              <></>
                           )}
                           <Typography variant="body2" component="p">
                              <span
                                 className="agra-title-color"
                                 dangerouslySetInnerHTML={{
                                    __html: a.description
                                 }}></span>
                           </Typography>
                        </div>
                     );
                  })}
               </Paper>
            </React.Fragment>
         );
      }
   }
}

export default withStyles(styles)(withRouter(Agenda));
