import WebSock from "./WebSock";

export interface IWSEventHandler {
   getWSNamespaces(): Array<string>;

   onWSMessage(cmd: string, msg: string): void;

   onWSDisconnect(): void;

   onWSConnect(): void;

   onWSisAlive(): void;
}

export default class WSEventHandler {
   private static instance: WSEventHandler;
   private hnds: { [key: string]: IWSEventHandler } = {};
   private wssock: WebSock | undefined = undefined;
   private lastMessages: { [key: string]: string } = {};

   private constructor() {}

   public static getInstance(): WSEventHandler {
      if (!WSEventHandler.instance) {
         WSEventHandler.instance = new WSEventHandler();
      }
      return WSEventHandler.instance;
   }

   public connectTo(event: string) {
      console.log("new WSSock() Connection to " + event);
      if (this.wssock !== undefined && this.wssock !== null) {
         try {
            this.wssock.disconnect();
            this.onDisconnect();
         } catch (exception) {
            console.error(exception);
         }
      }
      this.wssock = new WebSock(event);
      try {
         this.wssock.connect();
      } catch (exception) {
         console.error(exception);
      }
   }

   addEventHandler(hashCode: string, hnd: IWSEventHandler) {
      this.hnds[hashCode] = hnd;
      for (let cmd in this.lastMessages) {
         if (cmd === "*" || hnd.getWSNamespaces().includes(cmd)) {
            hnd.onWSMessage(cmd, this.lastMessages[cmd]);
         }
      }
   }

   removeEventHandler(hashCode: string) {
      delete this.hnds[hashCode];
   }

   public sendMessage(msg: string) {
      if (this.wssock !== undefined) {
         this.wssock.sendMessage(msg);
      }
   }

   public onMessage(cmd: string | undefined = "*", msg: string) {
      this.lastMessages[cmd] = msg;
      Object.keys(this.hnds).map((k) => {
         try {
            if (cmd === "*" || this.hnds[k].getWSNamespaces().includes(cmd)) {
               this.hnds[k].onWSMessage(cmd, msg);
            }
            return true;
         } catch (e) {
            console.error(e);
            return false;
         }
      });
   }

   public onDisconnect() {
      Object.keys(this.hnds).map((k) => {
         try {
            this.hnds[k].onWSDisconnect();
            return true;
         } catch (e) {
            console.error(e);
            return false;
         }
      });
   }

   public isAlive() {
      Object.keys(this.hnds).map((k) => {
         try {
            this.hnds[k].onWSisAlive();
            return true;
         } catch (e) {
            console.error(e);
            return false;
         }
      });
   }

   public onConnect() {
      Object.keys(this.hnds).map((k) => {
         try {
            this.hnds[k].onWSConnect();
            return true;
         } catch (e) {
            console.error(e);
            return false;
         }
      });
   }
}
