import React from "react";
import "date-fns";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Stream } from "../lib/Types";
import { __Post } from "../lib/Rest";
import Chart from "react-apexcharts";
import { Button, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
   KeyboardTimePicker,
   KeyboardDatePicker,
   MuiPickersUtilsProvider
} from "@material-ui/pickers";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         margin: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {
   options: any;
   series: Array<any>;
   question: string;
   dateFrom: Date;
   dateUntil: Date;
}

class ViewStats extends BaseComponent<IProps, IStates> {
   interval: any = null;

   constructor(props: IProps) {
      super(props);

      this.state = {
         question: "",
         //    from: "2022-05-10 09:00:00.000000",
         dateFrom: moment().add(-60, "minutes").toDate(),
         dateUntil: new Date(),
         options: {},
         series: []
      };
   }

   componentDidMount() {
      this.getDataLine();
      this.interval = setInterval(() => {
         this.getDataLine();
      }, 10 * 1000);
   }

   getDataLine() {
      __Post(
         "viewStats",
         {
            event: this.props.currentStream.event,
            from: moment(this.state.dateFrom).format("YYYY-MM-DD HH:mm:ss"),
            until: moment(this.state.dateUntil).format("YYYY-MM-DD HH:mm:ss")
         },

         (res: any) => {
            let cats = new Array<any>();
            res.map((r: any) => {
               cats.push(r.time);
            });

            let o: any = {};
            let op = {
               chart: {
                  type: "line"
               },
               stroke: {
                  curve: "smooth"
               },
               xaxis: {
                  type: "category",
                  categories: cats,
                  tickPlacement: "between",
                  labels: {
                     show: true,
                     rotate: -45,
                     hideOverlappingLabels: true
                  }
               }
            };

            let data = new Array<any>();
            res.map((r: any) => {
               data.push(r.users);
            });

            let series = [
               {
                  name: "CurrentUsers",
                  data: data
               }
            ];
            console.log("result", o, op);
            this.setState({ options: op, series: series });
         }
      );
   }

   render() {
      const { classes } = this.props;
      console.log();
      return (
         <React.Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
               <div className={classes.root}>
                  <Grid
                     container
                     spacing={2}
                     direction="row"
                     justifyContent={"center"}
                     alignItems="center">
                     <Grid item xs={12}>
                        <Typography align="center" variant="body1">
                           {this.props.currentStream.event}
                        </Typography>
                     </Grid>
                     <Grid item>
                        <KeyboardDatePicker
                           disableToolbar
                           variant="inline"
                           format="dd.MM.yyyy"
                           margin="normal"
                           id="date-picker-inline"
                           label="Date picker inline"
                           value={this.state.dateFrom}
                           onChange={(date: Date | null) => {
                              if (date !== null) {
                                 this.setState({ dateFrom: date });
                              }
                           }}
                           KeyboardButtonProps={{
                              "aria-label": "change date"
                           }}
                        />
                        <KeyboardTimePicker
                           margin="normal"
                           id="time-picker"
                           ampm={false}
                           label="Time picker"
                           value={this.state.dateFrom}
                           onChange={(date: Date | null) => {
                              if (date !== null) {
                                 this.setState({ dateFrom: date });
                              }
                           }}
                           KeyboardButtonProps={{
                              "aria-label": "change time"
                           }}
                        />
                     </Grid>
                     <Grid item> bis </Grid>
                     <Grid item>
                        <KeyboardDatePicker
                           disableToolbar
                           variant="inline"
                           format="dd.MM.yyyy"
                           margin="normal"
                           id="date-picker-inline"
                           label="Date picker inline"
                           value={this.state.dateUntil}
                           onChange={(date: Date | null) => {
                              if (date !== null) {
                                 this.setState({ dateUntil: date });
                              }
                           }}
                           KeyboardButtonProps={{
                              "aria-label": "change date"
                           }}
                        />
                        <KeyboardTimePicker
                           margin="normal"
                           id="time-picker"
                           ampm={false}
                           label="Time picker"
                           value={this.state.dateUntil}
                           onChange={(date: Date | null) => {
                              if (date !== null) {
                                 this.setState({ dateUntil: date });
                              }
                           }}
                           KeyboardButtonProps={{
                              "aria-label": "change time"
                           }}
                        />
                     </Grid>
                     <Grid item>
                        <Button
                           variant={"contained"}
                           color={"primary"}
                           onClick={() => this.getDataLine()}>
                           show
                        </Button>
                     </Grid>

                     <Grid item xs={12} style={{ paddingBottom: 100 }}>
                        <Chart
                           options={this.state.options}
                           series={this.state.series}
                           type="line"
                        />
                     </Grid>
                  </Grid>
               </div>
            </MuiPickersUtilsProvider>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(ViewStats));
