import React from "react";
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import IncastStreamingApp from "./IncastStreamingApp";

export default function App() {
   return (
      <Router>
         <IncastStreamingApp />
      </Router>
   );
}
