import { __Post } from "./Rest";
import { emptyCallBack } from "./Types";
import { SHA1, enc } from "crypto-js";

const i18n = {
   currentLocale: "en",
   translation: {
      en: {
         key: "value"
      }
   },

   init: function (callBack: emptyCallBack) {
      __Post("getI18n", {}, (res: any) => {
         this.translation = res;
         callBack();
      });
   },

   setLocale: function (locale: string = "de") {
      console.log("SET LOCALE:", locale);
      this.currentLocale = locale;
   },

   getLocale: function () {
      return this.currentLocale;
   },

   getEN: function (def = ""): string {
      const crypto = require("crypto");
      let value = def;
      let hook = crypto
         .createHash("sha1")
         .update(def.length + "###" + def)
         .digest("hex");

      if (
         // @ts-ignore
         this.translation["en"] !== undefined &&
         // @ts-ignore
         this.translation["en"][hook] !== undefined
      ) {
         // @ts-ignore
         value = this.translation["en"][hook];
      } else {
         __Post("appendI18n", { hook: hook, val: def });
      }
      return value;
   },

   get: function (def = ""): string {
      let value = def;
      let hook = SHA1(def.length + "###" + def).toString(enc.Hex);

      if (
         // @ts-ignore
         this.translation[this.currentLocale] !== undefined &&
         // @ts-ignore
         this.translation[this.currentLocale][hook] !== undefined
      ) {
         // @ts-ignore
         value = this.translation[this.currentLocale][hook];
      } else {
         __Post("appendI18n", { hook: hook, val: def });
      }
      return value;
   }
};

export { i18n };
