import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "./../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Stream } from "../lib/Types";
import { sendinblue } from "../lib/sendinblue";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {}

class SendInBlue extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {};
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      const { classes, history, currentStream } = this.props;
      if (currentStream.enableTecChat === true) {
         sendinblue();
      }
      return <React.Fragment></React.Fragment>;
   }
}

export default withStyles(styles)(withRouter(SendInBlue));
