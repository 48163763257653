import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "./lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   List,
   ListItem,
   ListItemIcon,
   ListItemText,
   ListSubheader,
   Paper
} from "@material-ui/core";
import { Stream } from "./lib/Types";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";
import Agenda from "./components/Agenda";
import WSEventHandler, { IWSEventHandler } from "./lib/WSEventHandler";
import { i18n } from "./lib/i18n";
import Session from "./lib/Session";
import { ReactComponent } from "*.svg";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      interactive: {
         padding: theme.spacing(2),
         marginBottom: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
   changeStream: (s: Stream) => void;
}

interface IStates {
   interactiveType: string;
}

class Menu extends BaseComponent<IProps, IStates> implements IWSEventHandler {
   constructor(props: IProps) {
      super(props);
      this.state = {
         interactiveType: ""
      };
   }

   componentDidMount() {
      WSEventHandler.getInstance().addEventHandler(super.getHashCode(), this);
   }

   componentWillUnmount() {
      WSEventHandler.getInstance().removeEventHandler(super.getHashCode());
   }

   getWSNamespaces() {
      return ["interactiveType"];
   }

   onWSMessage(cmd: string, msg: string) {
      console.log("Menu", "onWSMessage", cmd, msg);
      if (cmd === "interactiveType") {
         this.setState({ interactiveType: msg });
      }
   }

   onWSDisconnect() {}

   onWSisAlive() {}

   onWSConnect() {}

   render() {
      const { classes, currentStream, changeStream } = this.props;
      return (
         <React.Fragment>
            <div className={classes.root}>
               <List subheader={<ListSubheader>Streams</ListSubheader>}>
                  {currentStream.alternative.map((s) => {
                     return (
                        <ListItem
                           key={s.id}
                           button
                           onClick={() => changeStream(s)}>
                           <ListItemIcon>
                              {currentStream.id === s.id ? (
                                 <FiberManualRecordIcon />
                              ) : (
                                 <FiberManualRecordOutlinedIcon />
                              )}
                           </ListItemIcon>
                           <ListItemText primary={s.title} />
                        </ListItem>
                     );
                  })}
               </List>
               <hr />
               {this.state.interactiveType.startsWith(
                  "SHOW_BY_INSTITUTION:"
               ) ? (
                  <Paper className={classes.interactive}>
                     <div
                        dangerouslySetInnerHTML={{
                           __html: i18n.get(
                              "SHOW_BY_INSTITUTION_" +
                                 currentStream.event +
                                 "_" +
                                 this.state.interactiveType.split(":")[1] +
                                 "_" +
                                 Session.getInstance().getUser().institution
                           )
                        }}></div>
                  </Paper>
               ) : (
                  ""
               )}
               <Agenda currentStream={currentStream} fullAgenda={true} />
            </div>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(Menu));
