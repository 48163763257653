import React from "react";
import clsx from "clsx";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import {
   Avatar,
   Button,
   Collapse,
   Container,
   FormControl,
   Grid,
   IconButton,
   InputAdornment,
   InputLabel,
   OutlinedInput,
   Paper,
   Typography
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helper } from "../lib/Helper";
import { __Post } from "../lib/Rest";
import { Stream, User } from "../lib/Types";
import Session from "../lib/Session";
import { i18n } from "../lib/i18n";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         paddingTop: theme.spacing(0),
         paddingBottom: theme.spacing(3)
      },
      paper: {
         padding: theme.spacing(2)
      },
      cont: {
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(2),
         display: "flex",
         flexDirection: "column",
         alignItems: "center"
      },
      form: {
         width: "100%", // Fix IE 11 issue.
         marginTop: theme.spacing(1)
      },
      submit: {
         height: "100%",
         margin: theme.spacing(0, 1, 0)
      },
      margin: {
         //         margin: theme.spacing(1)
      },
      marginTop: {
         marginTop: theme.spacing(3)
      },
      withoutLabel: {
         marginTop: theme.spacing(3)
      },
      textField: {
         width: "100%"
      },
      backdrop: {
         zIndex: theme.zIndex.drawer + 1,
         color: "#fff"
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   onSuccess: string;
   currentStream: Stream;
}

interface IStates {
   showPassword: boolean;
   password: string;
   doLogin: boolean;
   showAlert: boolean;
}

class PasswordLogin extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         showPassword: false,
         //    password: "EXM_EFO_2021",
         password: "",
         doLogin: false,
         showAlert: false
      };
   }

   tryLogin() {
      __Post(
         "login",
         { email: this.props.currentStream.event, pass: this.state.password },
         (res: Array<User>) => {
            if (res.length === 0) {
               this.setState({ showAlert: true }, () => {
                  setTimeout(() => this.setState({ showAlert: false }), 5000);
               });
            } else if (res.length === 1) {
               let u: User = res[0];
               u.id = -1; //Invalidate User
               Session.getInstance().setUser(u);
               this.props.history.replace(this.props.onSuccess);
            }
         }
      );
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      const { classes, currentStream } = this.props;
      const _password: boolean = Helper.validatorMin(this.state.password, 4);
      return (
         <React.Fragment>
            <Backdrop className={classes.backdrop} open={this.state.doLogin}>
               <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container className={classes.root}>
               <Grid item xs={12}>
                  <Paper
                     elevation={1}
                     variant="elevation"
                     className={classes.paper}>
                     <Grid container>
                        <Grid item xs={8}>
                           <FormControl
                              className={clsx(
                                 classes.margin,
                                 classes.textField
                              )}
                              variant="outlined">
                              <InputLabel htmlFor="outlined-adornment-password">
                                 {i18n.get("Passwort")}
                              </InputLabel>
                              <OutlinedInput
                                 error={!_password}
                                 id="outlined-adornment-password"
                                 type={
                                    this.state.showPassword
                                       ? "text"
                                       : "password"
                                 }
                                 value={this.state.password}
                                 onChange={(e) => {
                                    this.setState({ password: e.target.value });
                                 }}
                                 endAdornment={
                                    <InputAdornment position="end">
                                       <IconButton
                                          aria-label="Visi"
                                          onClick={() => {
                                             this.setState({
                                                showPassword:
                                                   !this.state.showPassword
                                             });
                                          }}
                                          edge="end">
                                          {this.state.showPassword ? (
                                             <Visibility />
                                          ) : (
                                             <VisibilityOff />
                                          )}
                                       </IconButton>
                                    </InputAdornment>
                                 }
                                 labelWidth={70}
                              />
                           </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                           <Button
                              onClick={() => this.tryLogin()}
                              disabled={!_password}
                              size="large"
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              className={classes.submit}>
                              {i18n.get("PasswordOnlyAnmelden")}
                           </Button>
                        </Grid>

                        <Collapse
                           in={this.state.showAlert}
                           className={classes.textField}>
                           <Grid item xs={12} className={classes.marginTop}>
                              <Alert
                                 className={classes.textField}
                                 severity="error"
                                 action={
                                    <IconButton
                                       aria-label="close"
                                       color="inherit"
                                       size="small"
                                       onClick={() => {
                                          this.setState({ showAlert: false });
                                       }}>
                                       <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                 }>
                                 {i18n.get("Anmelden fehlgeschlagen.")}
                              </Alert>
                           </Grid>
                        </Collapse>
                     </Grid>
                  </Paper>
               </Grid>
            </Grid>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(PasswordLogin));
