import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Stream } from "../lib/Types";
import { __Post } from "../lib/Rest";
import Chart from "react-apexcharts";
import { Grid, Typography } from "@material-ui/core";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {
   options: any;
   series: Array<any>;
   question: string;
}

class StatsDonut extends BaseComponent<IProps, IStates> {
   interval: any = null;

   constructor(props: IProps) {
      super(props);
      this.state = {
         question: "",
         options: {
            chart: {
               type: "donut"
            },
            responsive: [
               {
                  breakpoint: 480,
                  options: {
                     chart: {
                        width: 800
                     },
                     legend: {
                        position: "bottom"
                     }
                  }
               }
            ]
         },
         series: []
      };
   }

   componentDidMount() {
      this.getPollData();
      this.interval = setInterval(() => {
         this.getPollData();
      }, 10 * 1000);
   }

   getPollData() {
      __Post(
         "getPoll",
         {
            event: this.props.currentStream.event,
            id: this.props.match.params.id
         },
         (res: any) => {
            let opti = [];
            let free = false;
            console.log("Poll-Res", res[0]);
            if (res[0].valueset !== "FREETEXT") {
               opti = res[0].valueset.split("#$#");
            } else {
               free = true;
            }
            let states: any = {
               loading: false,
               question: res[0].question,
               options: opti,
               freeText: free
            };
            this.setState(states);
         }
      );

      __Post(
         "getPollVotes",
         {
            event: this.props.currentStream.event,
            id: this.props.match.params.id
         },
         (res: any) => {
            console.log("Poll-Res", res);

            let o: any = {};
            let max = 0;
            let sum = 0;

            let op = {
               labels: [],

               chart: {
                  type: "donut",
                  width: 1900
               },
               /*
                     plotOptions: {
                      pie: {
                        startAngle: -90,
                       endAngle: 270,
                     },
                  },
                  */
               dataLabels: {
                  show: true,
                  style: {
                     colors: [],
                     fontSize: "18px",
                     fontWeight: 800,
                     cssClass: "apexcharts-data-label"
                  },
                  formatter: (v: any) => {
                     return parseInt(v) + "%";
                  }
               },

               colors: [
                  "#c20000",
                  "#0068af",
                  "#576874",
                  "#7f9a3d",
                  "#46aa28",
                  "#99c200",
                  "#fec800"
               ],

               /*
                  fill: {
                    type: "gradient",
                  },
                  */

               legend: {
                  formatter: (v: any) => {
                     return " " + v;
                  },
                  show: true,
                  fontSize: "20px",
                  markers: {
                     width: 25,
                     radius: 15,
                     height: 25
                  },
                  itemMargin: {
                     horizontal: 5,
                     vertical: 10
                  }
               }
            };

            let sr: Array<any> = [];

            res.map((v: any | undefined) => {
               if (o[v.value] === undefined) {
                  o[v.value] = [];
               }
               o[v.value].push(v);
               sum++;
               if (o[v.value].length > max) {
                  max = o[v.value].length;
               }
            });

            Object.keys(o).map((k: string) => {
               let v = o[k];
               let pro = (v.length * 100) / max;
               let prozent = (v.length * 100) / sum;
               let top = (900 * pro) / 100;
               let f = (218 * pro) / 100;
               let size = 1800 / Object.keys(o).length;

               // @ts-ignore
               op.labels.push(k);
               sr.push(parseInt("" + prozent));
            });
            console.log("result", o, op);
            this.setState({ options: op, series: sr });
         }
      );
   }

   render() {
      const { classes, history } = this.props;

      return (
         <React.Fragment>
            <div className={classes.root}>
               <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <Grid item xs={12}>
                     <Typography align="center" variant="h3">
                        {this.state.question}
                     </Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="donut"
                        width="1500"
                        height="800"
                     />
                  </Grid>
               </Grid>
            </div>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(StatsDonut));
