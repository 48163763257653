import React from "react";
import clsx from "clsx";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import {
   Avatar,
   Button,
   Collapse,
   Container,
   FormControl,
   Grid,
   IconButton,
   InputAdornment,
   InputLabel,
   OutlinedInput,
   Paper,
   Typography
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helper } from "../lib/Helper";
import { __Post } from "../lib/Rest";
import { Stream, User } from "../lib/Types";
import Session from "../lib/Session";
import { i18n } from "../lib/i18n";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      paper: {
         paddingTop: theme.spacing(5),
         paddingBottom: theme.spacing(5),
         display: "flex",
         flexDirection: "column",
         alignItems: "center"
      },
      avatar: {
         margin: theme.spacing(1),
         backgroundColor: theme.palette.secondary.main
      },
      form: {
         width: "100%", // Fix IE 11 issue.
         marginTop: theme.spacing(1)
      },
      submit: {
         margin: theme.spacing(3, 0, 2)
      },
      margin: {
         margin: theme.spacing(1)
      },
      withoutLabel: {
         marginTop: theme.spacing(3)
      },
      textField: {
         width: "100%"
      },
      backdrop: {
         zIndex: theme.zIndex.drawer + 1,
         color: "#fff"
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   onSuccess: string;
   currentStream: Stream;
}

interface IStates {
   showPassword: boolean;
   password: string;
   email: string;
   doLogin: boolean;
   showAlert: boolean;
}

class Login extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         showPassword: false,
         password: "",
         email: "",
         doLogin: false,
         showAlert: false
      };
   }

   tryLogin() {
      __Post(
         "login",
         { email: this.state.email.trim(), pass: this.state.password.trim() },
         (res: Array<User>) => {
            if (res.length === 0) {
               this.setState({ showAlert: true }, () => {
                  setTimeout(() => this.setState({ showAlert: false }), 5000);
               });
            } else if (res.length === 1) {
               let u: User = res[0];
               Session.getInstance().setUser(u);
               this.props.history.replace(this.props.onSuccess);
            }
         }
      );
   }

   componentDidMount() {}

   componentWillUnmount() {}

   showButton(email: boolean, password: boolean) {
      if (this.state.doLogin) {
         return true;
      }
      if (email && password) {
         return false;
      }
      return true;
   }

   render() {
      const { classes } = this.props;
      const _email: boolean = Helper.validatorEmail(this.state.email);
      const _password: boolean = Helper.validatorMin(this.state.password, 4);
      return (
         <React.Fragment>
            <Backdrop className={classes.backdrop} open={this.state.doLogin}>
               <CircularProgress color="inherit" />
            </Backdrop>

            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Paper
                     elevation={1}
                     variant="elevation"
                     className={classes.paper}>
                     <Container
                        component="div"
                        maxWidth="xs"
                        className={classes.paper}>
                        <Avatar className={classes.avatar}>
                           <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                           {i18n.get("Anmeldung")}
                        </Typography>

                        <FormControl
                           className={clsx(classes.margin, classes.textField)}
                           variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-email">
                              {i18n.get("Email")}
                           </InputLabel>
                           <OutlinedInput
                              error={!_email}
                              id="outlined-adornment-email"
                              type={"text"}
                              value={this.state.email}
                              onChange={(e) => {
                                 this.setState({ email: e.target.value });
                              }}
                              labelWidth={50}
                           />
                        </FormControl>

                        <FormControl
                           className={clsx(classes.margin, classes.textField)}
                           variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-password">
                              {i18n.get("Passwort")}
                           </InputLabel>
                           <OutlinedInput
                              error={!_password}
                              id="outlined-adornment-password"
                              type={
                                 this.state.showPassword ? "text" : "password"
                              }
                              value={this.state.password}
                              onChange={(e) => {
                                 this.setState({ password: e.target.value });
                              }}
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton
                                       aria-label="Visi"
                                       onClick={() => {
                                          this.setState({
                                             showPassword:
                                                !this.state.showPassword
                                          });
                                       }}
                                       edge="end">
                                       {this.state.showPassword ? (
                                          <Visibility />
                                       ) : (
                                          <VisibilityOff />
                                       )}
                                    </IconButton>
                                 </InputAdornment>
                              }
                              labelWidth={70}
                           />
                        </FormControl>

                        <Button
                           onClick={() => this.tryLogin()}
                           disabled={this.showButton(_email, _password)}
                           type="submit"
                           fullWidth
                           variant="contained"
                           color="primary"
                           className={classes.submit}>
                           {i18n.get("Anmelden")}
                        </Button>
                        <Collapse
                           in={this.state.showAlert}
                           className={classes.textField}>
                           <Alert
                              className={classes.textField}
                              severity="error"
                              action={
                                 <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                       this.setState({ showAlert: false });
                                    }}>
                                    <CloseIcon fontSize="inherit" />
                                 </IconButton>
                              }>
                              {i18n.get("Anmelden fehlgeschlagen.")}
                           </Alert>
                        </Collapse>
                     </Container>
                  </Paper>
               </Grid>
            </Grid>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(Login));
