import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "./../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Box, CardContent, Grid, Paper, Typography } from "@material-ui/core";
import { Stream } from "../lib/Types";
import { i18n } from "../lib/i18n";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      headerImg: {
         width: "100%",
         objectFit: "contain"
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {}

class Landing extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {};
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      const { classes, history, currentStream } = this.props;

      return (
         <React.Fragment>
            <Paper>
               <Grid container spacing={2} className={classes.root}>
                  <Grid item xs={12}>
                     <img
                        alt={currentStream.landingImage}
                        className={classes.headerImg}
                        src={currentStream.landingImage}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Typography variant="h4" component="h2">
                        {i18n.getEN(currentStream.event + "_HEADLINE")}
                     </Typography>
                     <Typography variant="h5" component="h2">
                        {i18n.getEN(currentStream.event + "_SUB_HEADLINE")}
                     </Typography>
                     <Box style={{ borderLeft: "3px #00E5DB solid" }}>
                        <CardContent>
                           <Typography variant="h6" component="h2">
                              {i18n.getEN(
                                 currentStream.event + "_LANDINGPAGE_LINE1"
                              )}
                           </Typography>
                           <Typography variant="h6" component="h2">
                              {i18n.getEN(
                                 currentStream.event + "_LANDINGPAGE_LINE2"
                              )}
                           </Typography>
                           <Typography variant="h6" component="h2">
                              {i18n.getEN(
                                 currentStream.event + "_LANDINGPAGE_LINE3"
                              )}
                           </Typography>
                        </CardContent>
                     </Box>
                  </Grid>
               </Grid>
            </Paper>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(Landing));
