import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   Button,
   ButtonGroup,
   Grid,
   Paper,
   TextField,
   Typography
} from "@material-ui/core";
import WSEventHandler, { IWSEventHandler } from "../lib/WSEventHandler";
import { PollOption, Stream } from "../lib/Types";
import { __Post } from "../lib/Rest";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      input: {
         width: "100%"
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {
   url: string;
   html: string;
   polls: any;
}

class AdmStats
   extends BaseComponent<IProps, IStates>
   implements IWSEventHandler
{
   constructor(props: IProps) {
      super(props);
      this.state = {
         url: "http://wwww.incast.de",
         html: "",
         polls: []
      };
   }

   getPolls() {
      __Post(
         "getPolls",
         { event: this.props.currentStream.event },
         (res: any) => {
            this.setState({ polls: res });
         }
      );
   }

   getWSNamespaces() {
      return ["reload", "setURL", "interactiveType"];
   }

   onWSMessage(cmd: string, msg: string) {
      if (cmd === "reload") {
      }
      if (cmd === "interactiveType") {
         this.setState({ html: msg });
      }
   }

   onWSDisconnect() {}

   onWSisAlive() {}

   onWSConnect() {}

   componentDidMount() {
      WSEventHandler.getInstance().addEventHandler(super.getHashCode(), this);
      this.getPolls();
   }

   componentWillUnmount() {
      WSEventHandler.getInstance().removeEventHandler(super.getHashCode());
   }

   render() {
      const { classes, currentStream } = this.props;
      return (
         <React.Fragment>
            <Paper className={classes.root}>
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <Typography variant={"h5"}>Buttons</Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField
                        id="filled-helperText"
                        className={classes.input}
                        label="IFrame URL"
                        defaultValue={this.state.url}
                        onChange={(e: any) =>
                           this.setState({ url: e.target.value })
                        }
                        variant="filled"
                        InputProps={{
                           endAdornment: (
                              <>
                                 <Button
                                    onClick={() => {
                                       WSEventHandler.getInstance().sendMessage(
                                          "toClient::setURL::" + this.state.url
                                       );
                                    }}>
                                    Set IFrame
                                 </Button>
                                 <Button
                                    onClick={() => {
                                       WSEventHandler.getInstance().sendMessage(
                                          "toClient::setURL::NULL"
                                       );
                                    }}>
                                    reset IFrame
                                 </Button>
                              </>
                           )
                        }}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <ButtonGroup
                        variant="contained"
                        color="primary"
                        aria-label="contained primary button group">
                        <Button
                           onClick={() => {
                              WSEventHandler.getInstance().sendMessage(
                                 "toClient::_reload::browser"
                              );
                           }}>
                           Force Reload Client Browser
                        </Button>
                        <Button
                           onClick={() => {
                              WSEventHandler.getInstance().sendMessage(
                                 "toClient::_reload::iframe"
                              );
                           }}>
                           Reload IFrame URL
                        </Button>
                     </ButtonGroup>
                  </Grid>
               </Grid>
            </Paper>

            <Paper className={classes.root}>
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <Typography variant={"h5"}>interactiveType</Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField
                        variant={"outlined"}
                        placeholder="<br/>"
                        value={this.state.html}
                        onChange={(e: any) => {
                           this.setState({ html: e.target.value });
                        }}
                        className={classes.input}
                        multiline
                        rows={3}
                        rowsMax={4}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <ButtonGroup
                        variant="contained"
                        color="primary"
                        aria-label="contained primary button group">
                        <Button
                           onClick={() => {
                              WSEventHandler.getInstance().sendMessage(
                                 "toClient::interactiveType::" + this.state.html
                              );
                           }}>
                           SEND InteractiveType
                        </Button>
                        <Button
                           color={"secondary"}
                           onClick={() => {
                              WSEventHandler.getInstance().sendMessage(
                                 "toClient::interactiveType::-"
                              );
                           }}>
                           Clear InteractiveType
                        </Button>
                     </ButtonGroup>
                  </Grid>
                  <Grid item xs={12}>
                     <ButtonGroup
                        variant="outlined"
                        color="inherit"
                        aria-label="contained primary button group">
                        <Button
                           onClick={() => {
                              this.setState({
                                 html: "POLL:[datenbank_poll_id]"
                              });
                           }}>
                           POLL
                        </Button>
                        <Button
                           color="inherit"
                           onClick={() => {
                              this.setState({
                                 html: "SHOW_BY_INSTITUTION:[ident]"
                              });
                           }}>
                           SHOW_BY_INSTITUTION
                        </Button>

                        {this.props.currentStream.interactiveButtons !== null
                           ? this.props.currentStream.interactiveButtons
                                .split(",")
                                .map((p: any) => {
                                   return (
                                      <Button
                                         key={p}
                                         color={"primary"}
                                         onClick={() => {
                                            this.setState({
                                               html: p
                                            });
                                         }}>
                                         {p}
                                      </Button>
                                   );
                                })
                           : ""}
                        {this.state.polls.map((p: any) => {
                           return (
                              <Button
                                 key={p.id}
                                 color={"primary"}
                                 onClick={() => {
                                    this.setState({
                                       html: "POLL:" + p.id
                                    });
                                 }}>
                                 {p.id}:{p.name}
                              </Button>
                           );
                        })}
                     </ButtonGroup>
                  </Grid>
               </Grid>
            </Paper>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(AdmStats));
