import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "./lib/BaseComponent";
import {
   Link,
   Route,
   RouteComponentProps,
   Switch,
   withRouter
} from "react-router-dom";
import { Helmet } from "react-helmet";
import Frame from "./Frame";
import {
   Button,
   ButtonGroup,
   Card,
   CardActions,
   CardContent,
   Fab,
   Grid,
   Hidden,
   Paper,
   ThemeProvider,
   Typography
} from "@material-ui/core";
import StreamPlayer from "./components/StreamPlayer";
import { __Post } from "./lib/Rest";
import { Stream } from "./lib/Types";
import FeedbackList from "./components/FeedbackList";
import Session from "./lib/Session";
import CircularProgress from "@material-ui/core/CircularProgress";
import createTheme from "@material-ui/core/styles/createTheme";
import WSEventHandler, { IWSEventHandler } from "./lib/WSEventHandler";
import PortableWifiOffIcon from "@material-ui/icons/PortableWifiOff";
import { grey, red } from "@material-ui/core/colors";
import AdmFeedbackButtons from "./components/AdmFeedbackButtons";
import Agenda from "./components/Agenda";
import Stats from "./components/Stats";
import AdmTools from "./components/AdmTools";
import AnnoLogin from "./components/AnnoLogin";
import FeedbackMod from "./components/FeedbackMod";
import Login from "./components/Login";
import StatsDonut from "./components/StatsDonut";
import StatsBar from "./components/StatsBar";
import AdmClientStats from "./components/AdmClientStats";
import { i18n } from "./lib/i18n";
import { lightThemeValues } from "./style/theme";
import Changebg from "./lib/Changebg";
import Landing from "./components/Landing";
import PasswordLogin from "./components/PasswordLogin";
import DSGVO from "./components/DSGVO";
import ViewStats from "./components/ViewStats";
import SendInBlue from "./components/SendInBlue";
import RegisterAndLogin from "./components/RegisterAndLogin";

const styles = (theme: Theme) =>
   createStyles({
      themeContainer: {},
      root: {
         padding: theme.spacing(2),
         height: "100hv"
      },
      menuButton: {
         marginRight: theme.spacing(2)
      },

      table: {
         minWidth: 350
      },
      title: {
         flexGrow: 1
      },
      list: {
         width: 320
      },
      feedbackView: {
         maxHeight: "70vh",
         overflowY: "scroll",
         overflowX: "hidden"
      },
      feedbackCommit: {},

      fab: {
         position: "fixed",
         bottom: theme.spacing(11),
         right: theme.spacing(4),
         opacity: 0.5
      },
      fabGrey: {
         color: theme.palette.common.white,
         backgroundColor: grey[500],
         "&:hover": {
            backgroundColor: grey[600]
         }
      },
      fabRed: {
         color: theme.palette.common.white,
         backgroundColor: red[500],
         "&:hover": {
            backgroundColor: red[600]
         }
      },
      bigLink: {
         fontSize: 20
      },
      paper: {
         paddingTop: theme.spacing(5),
         paddingBottom: theme.spacing(5),
         height: "100hv",
         display: "flex",
         flexDirection: "column",
         alignItems: "center"
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {}

interface IStates {
   allStreams: Array<Stream>;
   currentStream: Stream;
   loading: boolean;
   ip: string;
   wsConnected: boolean;
}

class IncastStreamingApp
   extends BaseComponent<IProps, IStates>
   implements IWSEventHandler
{
   constructor(props: IProps) {
      super(props);
      this.state = {
         loading: true,
         allStreams: [],
         currentStream: {
            id: -1,
            event: "unknown",
            lang: "en",
            title: "",
            url: "",
            defaultProvenQuality: "unknown",
            maxClients: 60,
            onlyMyself: false,
            likeable: true,
            streamFilter: "accept%",
            podiumFilter: "%podium%",
            feedbackFilter: "unknown",
            headerImage: "",
            landingImage: "",
            alternative: [],
            showAgendaTimes: false,
            showHeader: false,
            showNavbar: false,
            showLanding: false,
            navText: "#todo",
            feedbackReloadInterval: 40,
            showFeedbacks: true,
            theme: lightThemeValues,
            themeJSON: "{}",
            rootEvent: "",
            enableTecChat: false,
            showDSGVO: false,
            feedbackListByRootStream: false,
            interactiveButtons: "",
            footerImage: "",
            showFooter: false
         },
         ip: "0.0.0.0",
         wsConnected: false
      };
   }

   getTheme() {
      console.log(
         "this.state.currentStream.theme",
         this.state.currentStream.theme
      );
      return createTheme(this.state.currentStream.theme);
   }

   getEventName(): string | null {
      let param: string | null =
         new URLSearchParams(window.location.search).get("event") !== null
            ? new URLSearchParams(window.location.search).get("event")
            : "unknown";
      if (param === null || param === "unknown") {
         let pathElements: Array<string> = window.location.pathname.split("/");
         if (pathElements.length > 1) {
            param = pathElements[1];
         }
      }
      return param;
   }

   componentDidMount() {
      i18n.init(() => {
         __Post("echoIp", {}, (res: any) => {
            this.setState({ ip: res.ip });
            this.loadStreams((s: boolean) => {
               if (s) {
                  this.setState({ loading: false });
                  i18n.setLocale(this.state.currentStream.lang);
                  WSEventHandler.getInstance().addEventHandler(
                     super.getHashCode(),
                     this
                  );
                  setTimeout((args) => {
                     WSEventHandler.getInstance().connectTo(
                        this.state.currentStream.event
                     );
                  }, 50);
               } else {
                  this.setState({ loading: false });
               }
            });
         });
      });
   }

   componentWillUnmount() {
      WSEventHandler.getInstance().removeEventHandler(super.getHashCode());
   }

   getWSNamespaces() {
      return ["_reload"];
   }

   onWSMessage(cmd: string, msg: string) {
      if (cmd === "_reload" && msg === "browser") {
         window.location.reload();
      }
   }

   onWSDisconnect() {
      this.setState({ wsConnected: false });
   }

   onWSisAlive() {
      this.setState({ wsConnected: true });
   }

   onWSConnect() {
      this.setState({ wsConnected: true });
   }

   changeStream(s: Stream) {
      let pathElements: Array<string> = window.location.pathname.split("/");
      let url = window.location.pathname;
      if (pathElements.length > 3) {
         url = "/" + s.event + "/" + pathElements[2] + "/" + pathElements[3];
      }
      this.props.history.replace(url);
      this.setState({ loading: true }, () => {
         this.loadStreams((s: boolean) => {
            if (s) {
               this.setState({ loading: false });
               i18n.setLocale(this.state.currentStream.lang);
               WSEventHandler.getInstance().connectTo(
                  this.state.currentStream.event
               );
            } else {
               this.setState({ loading: false });
            }
         });
      });
   }

   loadStreams(cb: (s: boolean) => void) {
      let eventName = this.getEventName();
      if (eventName === "" || eventName === null || eventName === undefined) {
         this.setState({ allStreams: [] }, () => cb(false));
         return;
      }

      __Post("getEvent", { event: eventName }, (res: Array<Stream>) => {
         console.log(res);
         if (res.length > 0) {
            if (res[0].themeJSON !== null) {
               res[0].theme = JSON.parse(res[0].themeJSON);
            } else {
               res[0].theme = lightThemeValues;
            }
            this.setState(
               {
                  allStreams: res,
                  currentStream: res[0]
               },
               () => {
                  cb(true);
               }
            );
         } else {
            this.setState({ allStreams: [] }, () => cb(false));
         }
      });
   }

   render() {
      const { classes } = this.props;
      const { currentStream, allStreams, loading, ip } = this.state;

      if (loading) {
         return (
            <ThemeProvider theme={this.getTheme()}>
               <CircularProgress />
            </ThemeProvider>
         );
      }

      if (allStreams.length === 0) {
         return (
            <ThemeProvider theme={this.getTheme()}>
               <Switch>
                  <Route exact path="*">
                     <Helmet>
                        <title>
                           {i18n.get("Streaming Multicaster (invalid Event)")}
                        </title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <Changebg />
                     <Grid item xs={12}>
                        <Grid container spacing={2} className={classes.root}>
                           <Grid item xs={12}>
                              <Paper
                                 elevation={1}
                                 variant="elevation"
                                 className={classes.paper}>
                                 <Typography variant="h2">
                                    {i18n.get(
                                       "Streaming Multicaster (invalid Event)"
                                    )}
                                 </Typography>
                              </Paper>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Route>
               </Switch>
            </ThemeProvider>
         );
      }

      return (
         <ThemeProvider theme={this.getTheme()}>
            {!this.state.wsConnected ? (
               <Fab
                  className={classes.fab}
                  onClick={() => {
                     WSEventHandler.getInstance().sendMessage(
                        "getLastClientStates::all"
                     );
                  }}
                  size="small"
                  color={"secondary"}>
                  {<PortableWifiOffIcon />}
               </Fab>
            ) : (
               <></>
            )}
            <Switch>
               <Route exact path="/:event/stats/donut/:id">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>Stats {currentStream.event}</title>
                  </Helmet>
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <StatsDonut currentStream={currentStream} />
                  </div>
               </Route>
               <Route exact path="/:event/stats/bar/:id">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>Stats {currentStream.event}</title>
                  </Helmet>
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <StatsBar currentStream={currentStream} />
                  </div>
               </Route>
               <Route exact path="/:event/admin/viewstats">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>Stats {currentStream.event}</title>
                  </Helmet>
                  <div className={classes.themeContainer}>
                     <ViewStats currentStream={currentStream} />
                  </div>
               </Route>
               <Route exact path="/:event/admin/rootfeedback">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>FeedbackAdmin {currentStream.event}</title>
                  </Helmet>
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <Grid container spacing={2}>
                        <Grid item xs={12}>
                           <FeedbackMod
                              currentStream={currentStream}
                              ip={ip}
                              important={true}
                              showfeedbacks={false}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <FeedbackList
                              ip={ip}
                              showEmail={true}
                              eventName={currentStream.rootEvent + "%"}
                              filter={currentStream.feedbackFilter}
                              onlyMyself={false}
                              likeable={false}
                              showIsEmpty={true}
                              buttons={
                                 currentStream.showFeedbacks
                                    ? [
                                         {
                                            color: "secondary",
                                            s: "reject",
                                            title: "reject"
                                         },
                                         {
                                            color: "primary",
                                            s: "accept",
                                            title: "accept (Chat only)"
                                         },
                                         {
                                            color: "default",
                                            s: "accept-podium",
                                            title: "accept (Chat and Podium)"
                                         },
                                         {
                                            color: "primary",
                                            s: "podium-only",
                                            title: "accept (Podium)"
                                         }
                                      ]
                                    : [
                                         {
                                            color: "secondary",
                                            s: "reject",
                                            title: "reject"
                                         },
                                         {
                                            color: "primary",
                                            s: "podium-only",
                                            title: "to Podium"
                                         }
                                      ]
                              }
                              loadDiffs={false}
                              reloadTime={30}
                              fastMode={true}
                              showViewSizeSlider={true}
                           />
                        </Grid>
                     </Grid>
                  </div>
               </Route>
               <Route exact path="/:event/admin/feedback">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>FeedbackAdmin {currentStream.event}</title>
                  </Helmet>
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <Grid container spacing={2}>
                        <Grid item xs={12}>
                           <FeedbackMod
                              currentStream={currentStream}
                              ip={ip}
                              important={true}
                              showfeedbacks={false}
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <FeedbackList
                              ip={ip}
                              showEmail={true}
                              eventName={currentStream.event}
                              filter={currentStream.feedbackFilter}
                              onlyMyself={false}
                              likeable={false}
                              showIsEmpty={true}
                              buttons={
                                 currentStream.showFeedbacks
                                    ? [
                                         {
                                            color: "secondary",
                                            s: "reject",
                                            title: "reject"
                                         },
                                         {
                                            color: "primary",
                                            s: "accept",
                                            title: "accept (Chat only)"
                                         },
                                         {
                                            color: "default",
                                            s: "accept-podium",
                                            title: "accept (Chat and Podium)"
                                         },
                                         {
                                            color: "primary",
                                            s: "podium-only",
                                            title: "accept (Podium)"
                                         }
                                      ]
                                    : [
                                         {
                                            color: "secondary",
                                            s: "reject",
                                            title: "reject"
                                         },
                                         {
                                            color: "primary",
                                            s: "podium-only",
                                            title: "to Podium"
                                         }
                                      ]
                              }
                              loadDiffs={false}
                              reloadTime={30}
                              fastMode={true}
                              showViewSizeSlider={true}
                           />
                        </Grid>
                     </Grid>
                  </div>
               </Route>
               <Route exact path="/:event/admin/stats">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>Feedback Stats</title>
                  </Helmet>
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <Stats currentStream={currentStream} />
                  </div>
               </Route>
               <Route exact path="/:event/admin/rootpodium-feedback">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>Podium</title>
                  </Helmet>
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <FeedbackList
                        showEmail={true}
                        ip={ip}
                        eventName={currentStream.rootEvent + "%"}
                        filter={currentStream.podiumFilter}
                        onlyMyself={false}
                        likeable={false}
                        showIsEmpty={true}
                        showViewSizeSlider={true}
                        buttons={[
                           {
                              color: "default",
                              s: "answered",
                              title: "answered"
                           }
                        ]}
                        loadDiffs={false}
                        reloadTime={30}
                        fastMode={true}
                     />
                  </div>
               </Route>
               <Route exact path="/:event/admin/podium-feedback">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>Podium</title>
                  </Helmet>
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <FeedbackList
                        showEmail={true}
                        ip={ip}
                        eventName={currentStream.event}
                        filter={currentStream.podiumFilter}
                        onlyMyself={false}
                        likeable={false}
                        showIsEmpty={true}
                        showViewSizeSlider={true}
                        buttons={[
                           {
                              color: "default",
                              s: "answered",
                              title: "answered"
                           }
                        ]}
                        loadDiffs={false}
                        reloadTime={30}
                        fastMode={true}
                     />
                  </div>
               </Route>
               <Route exact path="/:event/admin/agenda">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>Agenda Admin</title>
                  </Helmet>
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <Agenda
                        currentStream={currentStream}
                        fullAgenda={false}
                        showAdminButtons={true}
                     />
                  </div>
               </Route>
               <Route exact path="/:event/admin/streamdeck">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>{currentStream.event}</title>
                  </Helmet>
                  <Changebg />
                  <Grid container spacing={2} className={classes.root}>
                     <Grid item xs={12}>
                        <AdmTools currentStream={currentStream} />
                     </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.root}>
                     <Grid item xs={6}>
                        <AdmFeedbackButtons
                           currentStream={currentStream}
                           buttons={[
                              {
                                 color: "default",
                                 s: "unknown",
                                 title: "unknown ALL"
                              },
                              {
                                 color: "secondary",
                                 s: "reject",
                                 title: "reject ALL"
                              },
                              {
                                 color: "primary",
                                 s: "accept",
                                 title: "Chat only ALL"
                              },
                              {
                                 color: "primary",
                                 s: "podium-only",
                                 title: "Podium only all"
                              },
                              {
                                 color: "primary",
                                 s: "accept-podium",
                                 title: "Chat and Podium ALL"
                              },
                              {
                                 color: "default",
                                 s: "answered",
                                 title: "answered ALL"
                              }
                           ]}
                        />
                        <hr />
                        <FeedbackList
                           eventName={currentStream.event}
                           showEmail={true}
                           filter={"%%"}
                           onlyMyself={false}
                           likeable={false}
                           ip={ip}
                           buttons={[
                              {
                                 color: "default",
                                 s: "unknown",
                                 title: "unknown"
                              },
                              {
                                 color: "secondary",
                                 s: "reject",
                                 title: "reject"
                              },
                              {
                                 color: "primary",
                                 s: "accept",
                                 title: "Chat only"
                              },
                              {
                                 color: "primary",
                                 s: "podium-only",
                                 title: "Podium only"
                              },
                              {
                                 color: "primary",
                                 s: "accept-podium",
                                 title: "Chat and Podium"
                              },
                              {
                                 color: "default",
                                 s: "answered",
                                 title: "answered"
                              }
                           ]}
                           loadDiffs={false}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <Agenda
                           currentStream={currentStream}
                           fullAgenda={false}
                           showAdminButtons={true}
                        />
                     </Grid>
                  </Grid>
               </Route>
               <Route exact path="/:event/admin/usercounter">
                  <Helmet>
                     <meta charSet="utf-8" />
                     <title>{currentStream.event}</title>
                  </Helmet>
                  <Grid container spacing={2} className={classes.root}>
                     <Grid item xs={12}>
                        <AdmClientStats currentStream={currentStream} />
                     </Grid>
                  </Grid>
               </Route>

               <Route exact path="/:event/frame/stream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     {Session.getInstance().hasValidUser() ||
                     Session.getInstance().hasValidEventUser(
                        currentStream.rootEvent
                     ) ? (
                        <Frame
                           currentStream={currentStream}
                           changeStream={(s: Stream) => this.changeStream(s)}>
                           <Grid item xs={12}>
                              <Grid
                                 container
                                 spacing={2}
                                 className={classes.root}>
                                 <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={currentStream.showFeedbacks ? 6 : 12}
                                    lg={currentStream.showFeedbacks ? 7 : 12}
                                    xl={currentStream.showFeedbacks ? 8 : 12}>
                                    <Grid
                                       container
                                       direction="row"
                                       justify="flex-end"
                                       alignItems="flex-start"
                                       spacing={2}>
                                       <Grid item xs={12}>
                                          <StreamPlayer
                                             stream={currentStream}
                                          />
                                       </Grid>
                                       <Hidden smDown>
                                          <Grid item xs={12}>
                                             <Agenda
                                                currentStream={currentStream}
                                                fullAgenda={false}
                                             />
                                          </Grid>
                                       </Hidden>
                                    </Grid>
                                 </Grid>
                                 {currentStream.showFeedbacks ? (
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={5}
                                       xl={4}>
                                       <FeedbackMod
                                          currentStream={currentStream}
                                          ip={ip}
                                       />
                                    </Grid>
                                 ) : null}
                              </Grid>
                           </Grid>
                        </Frame>
                     ) : (
                        <React.Fragment>
                           <SendInBlue currentStream={currentStream} />
                           <Grid container spacing={2} className={classes.root}>
                              {currentStream.showLanding ? (
                                 <Grid item xs={12}>
                                    <Landing currentStream={currentStream} />
                                 </Grid>
                              ) : (
                                 <Grid item xs={12}>
                                    <Login
                                       currentStream={currentStream}
                                       onSuccess={
                                          "/" +
                                          currentStream.event +
                                          "/frame/stream"
                                       }
                                    />
                                    <Landing currentStream={currentStream} />
                                 </Grid>
                              )}
                           </Grid>
                        </React.Fragment>
                     )}
                  </DSGVO>
               </Route>
               <Route exact path="/:event/frame/pwostream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     {Session.getInstance().hasValidUser() ||
                     Session.getInstance().hasValidEventUser(
                        currentStream.rootEvent
                     ) ? (
                        <Frame
                           currentStream={currentStream}
                           changeStream={(s: Stream) => this.changeStream(s)}>
                           <Grid item xs={12}>
                              <Grid
                                 container
                                 spacing={2}
                                 className={classes.root}>
                                 <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={currentStream.showFeedbacks ? 6 : 12}
                                    lg={currentStream.showFeedbacks ? 7 : 12}
                                    xl={currentStream.showFeedbacks ? 8 : 12}>
                                    <Grid
                                       container
                                       direction="row"
                                       justify="flex-end"
                                       alignItems="flex-start"
                                       spacing={2}>
                                       <Grid item xs={12}>
                                          <StreamPlayer
                                             stream={currentStream}
                                          />
                                       </Grid>
                                       <Hidden smDown>
                                          <Grid item xs={12}>
                                             <Agenda
                                                currentStream={currentStream}
                                                fullAgenda={false}
                                             />
                                          </Grid>
                                       </Hidden>
                                    </Grid>
                                 </Grid>
                                 {currentStream.showFeedbacks ? (
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={5}
                                       xl={4}>
                                       <FeedbackMod
                                          currentStream={currentStream}
                                          ip={ip}
                                       />
                                    </Grid>
                                 ) : null}
                              </Grid>
                           </Grid>
                        </Frame>
                     ) : (
                        <Grid container spacing={2} className={classes.root}>
                           {currentStream.showLanding ? (
                              <Grid item xs={12}>
                                 <Landing currentStream={currentStream} />
                              </Grid>
                           ) : (
                              <Grid item xs={12}>
                                 <PasswordLogin
                                    currentStream={currentStream}
                                    onSuccess={
                                       "/" +
                                       currentStream.event +
                                       "/frame/pwostream"
                                    }
                                 />
                                 <Landing currentStream={currentStream} />
                              </Grid>
                           )}
                        </Grid>
                     )}
                  </DSGVO>
               </Route>

               <Route exact path="/:event/frame/pwostreamonly">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     {Session.getInstance().hasValidUser() ||
                     Session.getInstance().hasValidEventUser(
                        currentStream.rootEvent
                     ) ? (
                        <Frame
                           currentStream={currentStream}
                           changeStream={(s: Stream) => this.changeStream(s)}>
                           <Grid item xs={12}>
                              <Grid
                                 container
                                 spacing={2}
                                 className={classes.root}>
                                 <Grid item xs={12}>
                                    <Grid
                                       container
                                       direction="row"
                                       justify="flex-end"
                                       alignItems="flex-start"
                                       spacing={2}>
                                       <Grid item xs={12}>
                                          <StreamPlayer
                                             stream={currentStream}
                                          />
                                       </Grid>
                                       <Hidden smDown>
                                          <Grid item xs={12}>
                                             <Agenda
                                                currentStream={currentStream}
                                                fullAgenda={false}
                                             />
                                          </Grid>
                                       </Hidden>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Frame>
                     ) : (
                        <Grid container spacing={2} className={classes.root}>
                           {currentStream.showLanding ? (
                              <Grid item xs={12}>
                                 <Landing currentStream={currentStream} />
                              </Grid>
                           ) : (
                              <Grid item xs={12}>
                                 <PasswordLogin
                                    currentStream={currentStream}
                                    onSuccess={
                                       "/" +
                                       currentStream.event +
                                       "/frame/pwostreamonly"
                                    }
                                 />
                                 <Landing currentStream={currentStream} />
                              </Grid>
                           )}
                        </Grid>
                     )}
                  </DSGVO>
               </Route>

               <Route exact path="/:event/frame/pwregisterstream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     <div className={classes.themeContainer}>
                        {Session.getInstance().hasValidUser() ? (
                           <Frame
                              currentStream={currentStream}
                              changeStream={(s: Stream) =>
                                 this.changeStream(s)
                              }>
                              <Grid item xs={12}>
                                 <Grid
                                    container
                                    spacing={2}
                                    className={classes.root}>
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={currentStream.showFeedbacks ? 6 : 12}
                                       lg={currentStream.showFeedbacks ? 7 : 12}
                                       xl={
                                          currentStream.showFeedbacks ? 8 : 12
                                       }>
                                       <Grid
                                          container
                                          direction="row"
                                          justify="flex-end"
                                          alignItems="flex-start"
                                          spacing={2}>
                                          <Grid item xs={12}>
                                             <StreamPlayer
                                                stream={currentStream}
                                             />
                                          </Grid>
                                          <Hidden smDown>
                                             <Grid item xs={12}>
                                                <Agenda
                                                   currentStream={currentStream}
                                                   fullAgenda={false}
                                                />
                                             </Grid>
                                          </Hidden>
                                       </Grid>
                                    </Grid>
                                    {currentStream.showFeedbacks ? (
                                       <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={5}
                                          xl={4}>
                                          <FeedbackMod
                                             currentStream={currentStream}
                                             ip={ip}
                                          />
                                       </Grid>
                                    ) : null}
                                 </Grid>
                              </Grid>
                           </Frame>
                        ) : (
                           <Grid container spacing={2} className={classes.root}>
                              {currentStream.showLanding ? (
                                 <Grid item xs={12}>
                                    <Landing currentStream={currentStream} />
                                 </Grid>
                              ) : (
                                 <Grid item xs={12}>
                                    <RegisterAndLogin
                                       checkPassword={true}
                                       currentStream={currentStream}
                                       onSuccess={
                                          "/" +
                                          currentStream.event +
                                          "/frame/pwregisterstream"
                                       }
                                    />
                                 </Grid>
                              )}
                           </Grid>
                        )}
                     </div>
                  </DSGVO>
               </Route>

               <Route exact path="/:event/frame/pwrannoegisterstream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     <div className={classes.themeContainer}>
                        {Session.getInstance().hasValidUser() ? (
                           <Frame
                              currentStream={currentStream}
                              changeStream={(s: Stream) =>
                                 this.changeStream(s)
                              }>
                              <Grid item xs={12}>
                                 <Grid
                                    container
                                    spacing={2}
                                    className={classes.root}>
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={currentStream.showFeedbacks ? 6 : 12}
                                       lg={currentStream.showFeedbacks ? 7 : 12}
                                       xl={
                                          currentStream.showFeedbacks ? 8 : 12
                                       }>
                                       <Grid
                                          container
                                          direction="row"
                                          justify="flex-end"
                                          alignItems="flex-start"
                                          spacing={2}>
                                          <Grid item xs={12}>
                                             <StreamPlayer
                                                stream={currentStream}
                                             />
                                          </Grid>
                                          <Hidden smDown>
                                             <Grid item xs={12}>
                                                <Agenda
                                                   currentStream={currentStream}
                                                   fullAgenda={false}
                                                />
                                             </Grid>
                                          </Hidden>
                                       </Grid>
                                    </Grid>
                                    {currentStream.showFeedbacks ? (
                                       <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={6}
                                          lg={5}
                                          xl={4}>
                                          <FeedbackMod
                                             currentStream={currentStream}
                                             ip={ip}
                                          />
                                       </Grid>
                                    ) : null}
                                 </Grid>
                              </Grid>
                           </Frame>
                        ) : (
                           <Grid container spacing={2} className={classes.root}>
                              {currentStream.showLanding ? (
                                 <Grid item xs={12}>
                                    <Landing currentStream={currentStream} />
                                 </Grid>
                              ) : (
                                 <Grid item xs={12}>
                                    <AnnoLogin
                                       checkPassword={true}
                                       currentStream={currentStream}
                                       onSuccess={
                                          "/" +
                                          currentStream.event +
                                          "/frame/pwrannoegisterstream"
                                       }
                                    />
                                 </Grid>
                              )}
                           </Grid>
                        )}
                     </div>
                  </DSGVO>
               </Route>
               <Route exact path="/:event/frame/semiannostream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     <div className={classes.themeContainer}>
                        {Session.getInstance().hasValidUser() ? (
                           <Frame
                              currentStream={currentStream}
                              changeStream={(s: Stream) =>
                                 this.changeStream(s)
                              }>
                              <Grid item xs={12}>
                                 <Grid
                                    container
                                    spacing={2}
                                    className={classes.root}>
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={7}
                                       xl={8}>
                                       <Grid
                                          container
                                          direction="row"
                                          justify="flex-end"
                                          alignItems="flex-start"
                                          spacing={2}>
                                          <Grid item xs={12}>
                                             <StreamPlayer
                                                stream={currentStream}
                                             />
                                          </Grid>
                                          <Hidden smDown>
                                             <Grid item xs={12}>
                                                <Agenda
                                                   currentStream={currentStream}
                                                   fullAgenda={false}
                                                />
                                             </Grid>
                                          </Hidden>
                                       </Grid>
                                    </Grid>
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={5}
                                       xl={4}>
                                       <FeedbackMod
                                          currentStream={currentStream}
                                          ip={ip}
                                       />
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Frame>
                        ) : (
                           <Grid container spacing={2} className={classes.root}>
                              {currentStream.showLanding ? (
                                 <Grid item xs={12}>
                                    <Landing currentStream={currentStream} />
                                 </Grid>
                              ) : (
                                 <Grid item xs={12}>
                                    <AnnoLogin
                                       currentStream={currentStream}
                                       checkPassword={false}
                                       onSuccess={
                                          "/" +
                                          currentStream.event +
                                          "/frame/semiannostream"
                                       }
                                    />
                                 </Grid>
                              )}
                           </Grid>
                        )}
                     </div>
                  </DSGVO>
               </Route>
               {/** <Route exact path="/:event/frame/annostream">*/}
               <Route exact path="/:event/frame/annostream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     {currentStream.showLanding ? (
                        <Grid container spacing={2} className={classes.root}>
                           <Grid item xs={12}>
                              <Landing currentStream={currentStream} />
                           </Grid>
                        </Grid>
                     ) : (
                        <div className={classes.themeContainer}>
                           <Frame
                              currentStream={currentStream}
                              changeStream={(s: Stream) =>
                                 this.changeStream(s)
                              }>
                              <Grid item xs={12}>
                                 <Grid
                                    container
                                    spacing={2}
                                    className={classes.root}>
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={7}
                                       xl={8}>
                                       <Grid
                                          container
                                          direction="row"
                                          justify="flex-end"
                                          alignItems="flex-start"
                                          spacing={2}>
                                          <Grid item xs={12}>
                                             <StreamPlayer
                                                stream={currentStream}
                                             />
                                          </Grid>
                                          <Hidden smDown>
                                             <Grid item xs={12}>
                                                <Agenda
                                                   currentStream={currentStream}
                                                   fullAgenda={false}
                                                />
                                             </Grid>
                                          </Hidden>
                                       </Grid>
                                    </Grid>
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={5}
                                       xl={4}>
                                       <FeedbackMod
                                          currentStream={currentStream}
                                          ip={ip}
                                       />
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Frame>
                        </div>
                     )}
                  </DSGVO>
               </Route>

               <Route exact path="/:event/frame/feedbackAnndstream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     {currentStream.showLanding ? (
                        <Grid container spacing={2} className={classes.root}>
                           <Grid item xs={12}>
                              <Landing currentStream={currentStream} />
                           </Grid>
                        </Grid>
                     ) : (
                        <div className={classes.themeContainer}>
                           <Frame
                              currentStream={currentStream}
                              changeStream={(s: Stream) =>
                                 this.changeStream(s)
                              }>
                              <Grid item xs={12}>
                                 <Grid
                                    container
                                    spacing={2}
                                    className={classes.root}>
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={7}
                                       xl={8}>
                                       <Grid
                                          container
                                          direction="row"
                                          justify="flex-end"
                                          alignItems="flex-start"
                                          spacing={2}>
                                          <Grid item xs={12}>
                                             <StreamPlayer
                                                stream={currentStream}
                                             />
                                          </Grid>
                                          <Hidden smDown>
                                             <Grid item xs={12}>
                                                <Agenda
                                                   currentStream={currentStream}
                                                   fullAgenda={false}
                                                />
                                             </Grid>
                                          </Hidden>
                                       </Grid>
                                    </Grid>
                                    <Grid
                                       item
                                       xs={12}
                                       sm={12}
                                       md={6}
                                       lg={5}
                                       xl={4}>
                                       <FeedbackMod
                                          currentStream={currentStream}
                                          ip={ip}
                                          forceLogin={true}
                                       />
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Frame>
                        </div>
                     )}
                  </DSGVO>
               </Route>
               {/** <Route exact path="/:event/frame/streamonly">*/}
               <Route exact path="/:event/frame/streamonly">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <SendInBlue currentStream={currentStream} />
                     <Changebg />
                     <div className={classes.themeContainer}>
                        <Frame
                           currentStream={currentStream}
                           changeStream={(s: Stream) => this.changeStream(s)}>
                           <Grid item xs={12}>
                              <Grid
                                 container
                                 spacing={2}
                                 className={classes.root}>
                                 <Grid item xs={12}>
                                    <Grid
                                       container
                                       direction="row"
                                       justify="flex-end"
                                       alignItems="flex-start"
                                       spacing={2}>
                                       <Grid item xs={12}>
                                          <StreamPlayer
                                             stream={currentStream}
                                          />
                                       </Grid>
                                       <Hidden smDown>
                                          <Grid item xs={12}>
                                             <Agenda
                                                currentStream={currentStream}
                                                fullAgenda={false}
                                             />
                                          </Grid>
                                       </Hidden>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Frame>
                     </div>
                  </DSGVO>
               </Route>
               <Route exact path="/:event/iframe/agenda">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <meta charSet="utf-8" />
                        <title>Agenda</title>
                     </Helmet>
                     <Changebg />
                     <div className={classes.themeContainer}>
                        <Agenda
                           currentStream={currentStream}
                           fullAgenda={false}
                           showAdminButtons={false}
                        />
                     </div>
                  </DSGVO>
               </Route>
               <Route exact path="/:event/iframe/stream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <Changebg />
                     <div className={classes.themeContainer}>
                        <Grid item xs={12}>
                           <Grid container spacing={2} className={classes.root}>
                              <Grid item xs={12}>
                                 {Session.getInstance().hasValidUser() ? (
                                    <FeedbackMod
                                       currentStream={currentStream}
                                       ip={ip}
                                    />
                                 ) : (
                                    <Login
                                       onSuccess={
                                          "/" +
                                          currentStream.event +
                                          "/iframe/stream"
                                       }
                                       currentStream={currentStream}
                                    />
                                 )}
                              </Grid>
                           </Grid>
                        </Grid>
                     </div>
                  </DSGVO>
               </Route>
               <Route exact path="/:event/iframe/semiannostream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <Changebg />
                     <div className={classes.themeContainer}>
                        <Grid item xs={12}>
                           <Grid container spacing={2} className={classes.root}>
                              <Grid item xs={12}>
                                 {Session.getInstance().hasValidUser() ? (
                                    <FeedbackMod
                                       currentStream={currentStream}
                                       ip={ip}
                                    />
                                 ) : (
                                    <AnnoLogin
                                       currentStream={currentStream}
                                       checkPassword={false}
                                       onSuccess={
                                          "/" +
                                          currentStream.event +
                                          "/iframe/semiannostream"
                                       }
                                    />
                                 )}
                              </Grid>
                           </Grid>
                        </Grid>
                     </div>
                  </DSGVO>
               </Route>
               <Route exact path="/:event/iframe/annostream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <Changebg />
                     <div className={classes.themeContainer}>
                        <Grid item xs={12}>
                           <Grid container spacing={2} className={classes.root}>
                              <Grid item xs={12}>
                                 <FeedbackMod
                                    currentStream={currentStream}
                                    ip={ip}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                     </div>
                  </DSGVO>
               </Route>
               <Route exact path="/:event/iframe/feedbackAnndstream">
                  <DSGVO currentStream={currentStream}>
                     <Helmet>
                        <title>{currentStream.title}</title>
                        <link rel="canonical" href={window.location.href} />
                     </Helmet>
                     <Changebg />
                     <div className={classes.themeContainer}>
                        <Grid item xs={12}>
                           <Grid container spacing={2} className={classes.root}>
                              <Grid item xs={12}>
                                 <FeedbackMod
                                    currentStream={currentStream}
                                    ip={ip}
                                    forceLogin={true}
                                 />
                              </Grid>
                           </Grid>
                        </Grid>
                     </div>
                  </DSGVO>
               </Route>
               <Route exact path="*">
                  <Helmet>
                     <title>Streams</title>
                     <link rel="canonical" href={window.location.href} />
                  </Helmet>
                  <SendInBlue currentStream={currentStream} />
                  <Changebg />
                  <div className={classes.themeContainer}>
                     <Grid item xs={12}>
                        <Grid container spacing={2} className={classes.root}>
                           {allStreams.map((s) => {
                              return (
                                 <Grid key={s.id} item xs={12}>
                                    <Card>
                                       <CardContent>
                                          <Typography
                                             gutterBottom
                                             variant="h5"
                                             component="h2">
                                             {s.event}
                                          </Typography>
                                       </CardContent>
                                       <CardActions>
                                          <Grid container spacing={2}>
                                             <Grid item xs={12}>
                                                <ButtonGroup
                                                   variant="contained"
                                                   color="primary"
                                                   aria-label="outlined primary button group">
                                                   <Button
                                                      color="default"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/feedback"
                                                      }>
                                                      Feedback
                                                   </Button>

                                                   <Button
                                                      color="default"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/rootfeedback"
                                                      }>
                                                      Feedback-ROOT
                                                   </Button>

                                                   <Button
                                                      color="default"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/podium-feedback"
                                                      }>
                                                      Podium
                                                   </Button>

                                                   <Button
                                                      color="default"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/rootpodium-feedback"
                                                      }>
                                                      Podium-ROOT
                                                   </Button>
                                                   <Button
                                                      color="default"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/usercounter"
                                                      }>
                                                      UserStats
                                                   </Button>
                                                   <Button
                                                      color="secondary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/streamdeck"
                                                      }>
                                                      StreamDeck
                                                   </Button>
                                                   <Button
                                                      color="secondary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/stats"
                                                      }>
                                                      Stats
                                                   </Button>
                                                   <Button
                                                      color="secondary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/viewstats"
                                                      }>
                                                      ViewStats
                                                   </Button>
                                                   <Button
                                                      color="secondary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/admin/agenda"
                                                      }>
                                                      Agenda
                                                   </Button>
                                                </ButtonGroup>
                                             </Grid>
                                             <Grid item xs={12}>
                                                <ButtonGroup
                                                   variant="contained"
                                                   color="primary"
                                                   aria-label="outlined primary button group">
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/stream"
                                                      }>
                                                      Login
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/pwregisterstream"
                                                      }>
                                                      Register + PW (user)
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/pwannoregisterstream"
                                                      }>
                                                      Register + PW (event)
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/pwostream"
                                                      }>
                                                      PW-Only+QA
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/pwostreamonly"
                                                      }>
                                                      PW-Only
                                                   </Button>

                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/semiannostream"
                                                      }>
                                                      Register
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/annostream"
                                                      }>
                                                      Ohne + Feedback
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/streamonly"
                                                      }>
                                                      Ohne
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/frame/feedbackAnndstream"
                                                      }>
                                                      SpäterEinloggen
                                                   </Button>
                                                </ButtonGroup>
                                             </Grid>
                                             <Grid item xs={1}>
                                                <Typography variant="caption">
                                                   IFrame:
                                                </Typography>
                                             </Grid>
                                             <Grid item xs={11}>
                                                <ButtonGroup variant="contained">
                                                   <Button
                                                      color="secondary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/iframe/stream"
                                                      }>
                                                      Login
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/iframe/semiannostream"
                                                      }>
                                                      Register
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/iframe/annostream"
                                                      }>
                                                      Feedback
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/iframe/feedbackAnndstream"
                                                      }>
                                                      SpäterEinloggen
                                                   </Button>
                                                   <Button
                                                      color="primary"
                                                      component={Link}
                                                      to={
                                                         "/" +
                                                         s.event +
                                                         "/iframe/agenda"
                                                      }>
                                                      Agenda
                                                   </Button>
                                                </ButtonGroup>
                                             </Grid>
                                          </Grid>
                                       </CardActions>
                                    </Card>
                                 </Grid>
                              );
                           })}
                        </Grid>
                     </Grid>
                  </div>
               </Route>
            </Switch>
         </ThemeProvider>
      );
   }
}

export default withStyles(styles)(withRouter(IncastStreamingApp));
