import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   Avatar,
   Button,
   ButtonGroup,
   CardHeader,
   Collapse,
   FormControlLabel,
   FormGroup,
   Grid,
   Paper,
   Slider,
   Switch,
   Tooltip
} from "@material-ui/core";
import { __Post } from "../lib/Rest";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Feedback, Like, User } from "../lib/Types";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import Badge from "@material-ui/core/Badge";
import Session from "../lib/Session";
import PersonIcon from "@material-ui/icons/Person";
import WSEventHandler, { IWSEventHandler } from "../lib/WSEventHandler";
import { Helper } from "../lib/Helper";
import { i18n } from "../lib/i18n";

const styles = (theme: Theme) =>
   createStyles({
      root: {},
      likeBadge: {
         paddingLeft: theme.spacing(0)
      },
      prozess: {
         paddingTop: theme.spacing(2),
         paddingBottom: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   eventName: string;
   filter: string;
   buttons: Array<any>;
   ip: string;
   likeable: boolean | undefined;
   onlyMyself: boolean;
   loadDiffs: boolean;
   reloadTime?: number;
   fastMode?: boolean;
   showEmail?: boolean;
   showIsEmpty?: boolean;
   showViewSizeSlider?: boolean;
}

interface IStates {
   doUpdate: boolean;
   feedbacks: Array<Feedback>;
   newFeedback: boolean;
   viewSize: number | Array<number>;
}

const BLINDMODE_VIEW_SIZE = "BLINDMODE_VIEW_SIZE";

class FeedbackList
   extends BaseComponent<IProps, IStates>
   implements IWSEventHandler
{
   interval: any;
   saveReload: any;

   constructor(props: IProps) {
      super(props);
      let vs = 0.875;
      let rem = sessionStorage.getItem(BLINDMODE_VIEW_SIZE);
      if (rem !== undefined && rem !== null) {
         let _vs = parseFloat(rem);
         if (!isNaN(_vs)) {
            vs = _vs;
         }
      }

      this.state = {
         doUpdate: false,
         feedbacks: [],
         newFeedback: true,
         viewSize: vs
      };
   }

   like(f: Feedback) {
      if (Session.getInstance().hasValidUser()) {
         __Post(
            "appendLike",
            {
               feedback: f.id,
               userId: Session.getInstance().getUser().id,
               user: Session.getInstance().getUserName()
            },
            (res: any) => {
               this.updateFeeds();
            }
         );
      } else {
         __Post(
            "appendLike",
            { feedback: f.id, userId: 1, user: "Anonymous" },
            (res: any) => {
               this.updateFeeds();
            }
         );
      }
   }

   iLikeIt(l: Array<Like>) {
      let us: string = "";
      if (Session.getInstance().hasValidUser()) {
         us = Session.getInstance().getUserName();
      } else {
         us = "Anonymous";
      }
      let res = false;
      for (let i = 0; i < l.length; i++) {
         let like: Like = l[i];
         if (like.name === us && like.ip === this.props.ip) {
            res = true;
            break;
         }
      }
      return res;
   }

   updateFeeds() {
      if (this.state.doUpdate || !this.state.newFeedback) {
         return;
      }

      this.setState({ doUpdate: true }, () => {
         __Post(
            "getFeedbacks",
            {
               event: this.props.eventName,
               filter: this.props.filter,
               lastId: -1,
               exclude: new URLSearchParams(window.location.search).get(
                  "exclude"
               ),
               onlyUser: this.props.onlyMyself
                  ? Session.getInstance().getUser().id
                  : -1
            },
            (res: Array<Feedback>) => {
               this.setState({
                  doUpdate: false,
                  feedbacks: res.reverse(),
                  newFeedback: false
               });
            },
            (fail: any) => {
               this.setState({ doUpdate: false });
            }
         );
      });
   }

   likeNames(l: Array<Like>) {
      let res = [];
      for (let i = 0; i < l.length; i++) {
         let like: Like = l[i];
         res.push(like.name);
      }
      return res.join(", ");
   }

   getWSNamespaces() {
      return ["feedback"];
   }

   getUserName(u: User) {
      const { showEmail = false } = this.props;
      return (
         <span>
            {u.anrede} {Helper.nullAsEmpty(u.titel)} {u.vorname} {u.name}
            {Helper.nullAsEmpty(u.institution) !== "" ? (
               <Typography variant="caption" color="textSecondary">
                  {" "}
                  ({u.institution})
               </Typography>
            ) : (
               <React.Fragment></React.Fragment>
            )}
            {showEmail ? (
               <a href={"mailto:" + u.email}> {u.email}</a>
            ) : (
               <React.Fragment></React.Fragment>
            )}
         </span>
      );
   }

   onWSMessage(cmd: string, msg: string) {
      if (cmd === "feedback" && msg === "doReload") {
         const { fastMode = false } = this.props;
         if (fastMode) {
            this.setState({ newFeedback: true }, () => {
               this.updateFeeds();
            });
         } else {
            this.setState({ newFeedback: true });
         }
      }
   }

   onWSDisconnect() {}

   onWSisAlive() {}

   onWSConnect() {}

   componentDidMount() {
      const { reloadTime = 40 } = this.props;

      WSEventHandler.getInstance().addEventHandler(super.getHashCode(), this);
      this.updateFeeds();
      this.interval = setInterval(() => {
         this.updateFeeds();
      }, reloadTime * 1000);

      this.saveReload = setInterval(() => {
         this.setState({ newFeedback: true });
      }, 4 * reloadTime * 1000);
   }

   componentWillUnmount() {
      WSEventHandler.getInstance().removeEventHandler(super.getHashCode());
      clearInterval(this.interval);
      clearInterval(this.saveReload);
   }

   shouldComponentUpdate(nextProps: IProps, nextState: IStates) {
      if (nextProps.eventName !== this.props.eventName) {
         this.setState({ feedbacks: [] }, () => {
            this.updateFeeds();
         });
      }
      return true;
   }

   changeTo(_id: number, s: string) {
      __Post("update", { id: _id, provenQuality: s }, (res: any) => {
         this.updateFeeds();
         WSEventHandler.getInstance().sendMessage(
            "toClient::feedback::doReload"
         );
      });
   }

   render() {
      const {
         classes,
         likeable = false,
         showIsEmpty = false,
         showViewSizeSlider = false
      } = this.props;
      const { doUpdate, feedbacks } = this.state;

      return (
         <React.Fragment>
            {showViewSizeSlider ? (
               <Grid
                  container
                  spacing={0}
                  style={{
                     paddingLeft: "1%",
                     paddingRight: "1%",
                     paddingTop: "1%"
                  }}>
                  <Grid item xs={12} md={12}>
                     <Slider
                        value={this.state.viewSize}
                        step={0.1}
                        marks
                        min={0.7}
                        max={4}
                        onChangeCommitted={(event, value) => {
                           this.setState({ viewSize: value });
                           sessionStorage.setItem(
                              BLINDMODE_VIEW_SIZE,
                              "" + value
                           );
                        }}
                        onChange={(event, value) => {
                           this.setState({ viewSize: value });
                        }}
                     />
                  </Grid>
               </Grid>
            ) : null}

            <Grid
               container
               justify="center"
               alignItems="center"
               spacing={2}
               className={classes.prozess}>
               <Collapse in={doUpdate}>
                  <Grid item xs={12} className={classes.prozess}>
                     <CircularProgress color="primary" />
                  </Grid>
               </Collapse>

               {feedbacks.length === 0 ? (
                  <React.Fragment>
                     <Grid item>
                        <Typography variant={"h5"}>
                           {i18n.get("Aktuell keine Fragen")}
                        </Typography>
                     </Grid>
                  </React.Fragment>
               ) : (
                  <React.Fragment></React.Fragment>
               )}
               {feedbacks.map((f: Feedback) => {
                  var t = f.time.split(/[- :]/);
                  let d = new Date(
                     Date.UTC(
                        parseInt(t[0]),
                        parseInt(t[1]) - 1,
                        parseInt(t[2]),
                        parseInt(t[3]),
                        parseInt(t[4]),
                        parseInt(t[5])
                     )
                  );
                  d.setTime(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
                  return (
                     <Grid key={f.id} item xs={12}>
                        <Paper className={classes.root}>
                           <Card className={classes.root}>
                              <CardHeader
                                 avatar={
                                    <Avatar>
                                       <PersonIcon />
                                    </Avatar>
                                 }
                                 titleTypographyProps={{
                                    style: {
                                       fontSize: this.state.viewSize + "rem"
                                    }
                                 }}
                                 title={
                                    f.user === 1
                                       ? ""
                                       : f.user === 2
                                       ? "ADMIN"
                                       : this.getUserName(f.userData)
                                 }
                                 subheader={
                                    <React.Fragment>
                                       {this.props.buttons.length > 0
                                          ? f.likes.length + " Likes | "
                                          : ""}
                                       {Helper.timeSince(d)}
                                    </React.Fragment>
                                 }
                              />
                              <CardContent>
                                 <Typography
                                    variant="body2"
                                    component="p"
                                    style={{
                                       fontSize: this.state.viewSize + "rem"
                                    }}>
                                    {f.message}
                                 </Typography>
                              </CardContent>
                              {likeable &&
                              Session.getInstance().hasValidUser() ? (
                                 <CardActions>
                                    <IconButton
                                       onClick={() => this.like(f)}
                                       disabled={this.iLikeIt(f.likes)}>
                                       <ThumbUpAltOutlinedIcon />
                                    </IconButton>
                                    <Tooltip
                                       title={this.likeNames(f.likes)}
                                       placement="right"
                                       arrow>
                                       <Badge
                                          className={classes.likeBadge}
                                          badgeContent={f.likes.length}
                                          color="primary"
                                       />
                                    </Tooltip>
                                 </CardActions>
                              ) : (
                                 <React.Fragment />
                              )}
                              <CardActions>
                                 <ButtonGroup
                                    variant="contained"
                                    color="primary"
                                    aria-label="contained primary button group">
                                    {this.props.buttons.map((b) => {
                                       return (
                                          <Button
                                             variant={
                                                f.provenQuality === b.s
                                                   ? "outlined"
                                                   : "contained"
                                             }
                                             key={f.id + "_" + b.s}
                                             size="small"
                                             onClick={() =>
                                                this.changeTo(f.id, b.s)
                                             }
                                             color={b.color}>
                                             {b.title}
                                          </Button>
                                       );
                                    })}
                                 </ButtonGroup>
                              </CardActions>
                           </Card>
                        </Paper>
                     </Grid>
                  );
               })}
            </Grid>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(FeedbackList));
