import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { PollOption, Stream } from "../lib/Types";
import { __Post } from "../lib/Rest";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Brightness1OutlinedIcon from "@material-ui/icons/Brightness1Outlined";
import { Button, Collapse, Grid, Typography } from "@material-ui/core";
import Session from "../lib/Session";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      abstimmen: {
         /*
            color: "#ffffff",
            backgroundColor: "#c20000",
            "&:hover": {
              backgroundColor: "#a20000",
              boxShadow: "none",
            },
            */
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   id: number;
   currentStream: Stream;
}

interface IStates {
   question: string;
   options: Array<PollOption>;
   freeText: string;
   view: boolean;
   min: number;
   max: number;
}

class Poll extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         question: "",
         options: [],
         freeText: "",
         view: true,
         min: 1,
         max: 1
      };
   }

   componentDidMount() {
      this.getPoll();
   }

   shouldComponentUpdate(nextProps: IProps, nextState: IStates) {
      if (nextProps.id !== this.props.id) {
         return true;
      }
      return true;
   }

   componentWillUnmount() {}

   getPoll() {
      __Post(
         "getPoll",
         { event: this.props.currentStream.event, id: this.props.id },
         (res: any) => {
            let opti: Array<PollOption> = [];
            let free = false;
            console.log("Poll-Res", res[0]);

            if (res[0].valueset !== "FREETEXT") {
               let items = res[0].valueset.split("#$#");
               for (let i = 0; i < items.length; i++) {
                  opti.push({ text: items[i], value: false });
               }
            } else {
               free = true;
            }

            let states: any = {
               view: true,
               question: res[0].question,
               options: opti,
               freeText: free,
               min: parseInt(res[0].minChoice),
               max: parseInt(res[0].maxChoice)
            };
            console.log("Poll", states);
            this.setState(states);
         }
      );
   }

   validChoices() {
      let c: number = this.countChoices();
      return c >= this.state.min && c <= this.state.max;
   }

   countChoices() {
      let c: number = 0;
      for (let i = 0; i < this.state.options.length; i++) {
         let option = this.state.options[i];
         if (option.value) {
            c++;
         }
      }
      return c;
   }

   disableAny() {
      for (let i = 0; i < this.state.options.length; i++) {
         let option = this.state.options[i];
         option.value = false;
      }
   }

   vote() {
      console.log("getPoll()", this.props.id);
      let user = "";
      if (Session.getInstance().hasValidUser()) {
         user = Session.getInstance().getUserName();
      } else {
         user = "Anonymous";
      }
      for (let i = 0; i < this.state.options.length; i++) {
         let option = this.state.options[i];
         if (option.value) {
            __Post(
               "appendVote",
               {
                  event: this.props.currentStream.event,
                  id: this.props.id,
                  user: user,
                  value: option.text
               },
               (res: any) => {}
            );
         }
      }
      this.setState({ view: false });
   }

   render() {
      const { classes, history } = this.props;
      const valid = this.validChoices();
      const count = this.countChoices();
      return (
         <React.Fragment>
            <Collapse in={this.state.view}>
               <div className={classes.root}>
                  <Grid container spacing={2}>
                     <Grid item xs={12}>
                        <Typography variant="h5">
                           {" "}
                           {this.state.question}
                        </Typography>
                     </Grid>
                     {this.state.options.map((option) => {
                        return (
                           <Grid item xs={12}>
                              <FormControlLabel
                                 control={
                                    <Checkbox
                                       checked={option.value}
                                       color="primary"
                                       icon={<Brightness1OutlinedIcon />}
                                       checkedIcon={<CheckCircle />}
                                       onChange={(e) => {
                                          if (
                                             this.state.max === 1 &&
                                             this.state.min === 1
                                          ) {
                                             this.disableAny();
                                          }
                                          option.value = e.target.checked;
                                          this.forceUpdate();
                                       }}
                                    />
                                 }
                                 label={option.text}
                              />
                           </Grid>
                        );
                     })}
                     <Grid item xs={12}>
                        <>
                           {count > 0 ? (
                              <>
                                 {this.state.max === this.state.min ? (
                                    <>
                                       {!valid ? (
                                          <Typography
                                             color={
                                                !valid
                                                   ? "secondary"
                                                   : "textPrimary"
                                             }>
                                             Sie müssen {this.state.min}{" "}
                                             Antworte(n) wählen.
                                          </Typography>
                                       ) : (
                                          <></>
                                       )}
                                    </>
                                 ) : (
                                    <Typography
                                       color={
                                          !valid ? "secondary" : "textPrimary"
                                       }>
                                       {count} Antworten. Sie können zwischen{" "}
                                       {this.state.min} und {this.state.max}{" "}
                                       Antworten wählen.
                                    </Typography>
                                 )}
                              </>
                           ) : (
                              <></>
                           )}
                        </>
                        {valid ? (
                           <Button
                              className={classes.abstimmen}
                              variant="contained"
                              color="primary"
                              onClick={() => this.vote()}>
                              Abstimmen
                           </Button>
                        ) : (
                           <Button
                              className={classes.abstimmen}
                              variant="contained"
                              color="primary"
                              disabled>
                              Abstimmen
                           </Button>
                        )}
                     </Grid>
                  </Grid>
               </div>
               <hr />
            </Collapse>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(Poll));
