import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Stream } from "../lib/Types";
import { __Post } from "../lib/Rest";
import Chart from "react-apexcharts";
import { Grid, Typography } from "@material-ui/core";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
}

interface IStates {
   options: any;
   series: Array<any>;
   question: string;
}

class StatsBar extends BaseComponent<IProps, IStates> {
   interval: any = null;

   constructor(props: IProps) {
      super(props);
      this.state = {
         question: "",
         options: {
            responsive: [
               {
                  breakpoint: 480,
                  options: {
                     chart: {
                        width: 800
                     },
                     legend: {
                        position: "bottom"
                     }
                  }
               }
            ]
         },
         series: []
      };
   }

   componentDidMount() {
      this.getPollData();
      this.interval = setInterval(() => {
         this.getPollData();
      }, 10 * 1000);
   }

   componentWillUnmount() {}

   getPollData() {
      __Post(
         "getPoll",
         {
            event: this.props.currentStream.event,
            id: this.props.match.params.id
         },
         (res: any) => {
            let opti = [];
            let free = false;
            console.log("Poll-Res", res[0]);
            if (res[0].valueset !== "FREETEXT") {
               opti = res[0].valueset.split("#$#");
            } else {
               free = true;
            }
            let states: any = {
               loading: false,
               question: res[0].question,
               options: opti,
               freeText: free
            };
            this.setState(states);
         }
      );

      __Post(
         "getPollVotes",
         {
            event: this.props.currentStream.event,
            id: this.props.match.params.id
         },
         (res: any) => {
            console.log("Poll-Res", res);
            let o = {};
            let max = 0;
            let sum = 0;

            let op = {
               plotOptions: {
                  bar: {
                     distributed: false
                  }
               },
               chart: {
                  id: "basic-bar"
               },
               dataLabels: {
                  show: true,
                  style: {
                     colors: ["#fff"],
                     fontSize: "25px",
                     fontWeight: 800,
                     cssClass: "apexcharts-data-label"
                  },
                  formatter: (v: any) => {
                     return v + "%";
                  }
               },
               xaxis: {
                  categories: [],
                  labels: {
                     show: true,
                     trim: true,
                     rotate: 0,
                     rotateAlways: false,
                     style: {
                        colors: ["#000000"],
                        fontSize: "12px",
                        fontWeight: 400,
                        cssClass: "apexcharts-xaxis-label"
                     }
                  }
               },
               yaxis: {
                  labels: {
                     show: true,
                     trim: true,
                     rotate: 0,
                     rotateAlways: false,
                     style: {
                        colors: ["#000000"],
                        fontSize: "18px",
                        fontWeight: 400,
                        cssClass: "apexcharts-yaxis-label"
                     },
                     formatter: (v: any) => {
                        return v + "%";
                     }
                  }
               },
               colors: [
                  "#D51030",
                  "#D51030",
                  "#D51030",
                  "#D51030",
                  "#D51030",
                  "#D51030",
                  "#D51030",
                  "#D51030",
                  "#D51030",
                  "#D51030",
                  "#D51030"
               ]
            };

            let sr = [{ name: "series-1", data: [] }];

            res.map((v: any) => {
               // @ts-ignore
               if (o[v.value] === undefined) {
                  // @ts-ignore
                  o[v.value] = [];
               }
               // @ts-ignore
               o[v.value].push(v);
               sum++;
               // @ts-ignore
               if (o[v.value].length > max) {
                  // @ts-ignore
                  max = o[v.value].length;
               }
            });

            Object.keys(o).map((k) => {
               // @ts-ignore
               let v = o[k];
               let pro = (v.length * 100) / max;
               let prozent = (v.length * 100) / sum;
               let top = (900 * pro) / 100;
               let f = (218 * pro) / 100;
               let size = 1800 / Object.keys(o).length;

               if (k.length > 33) {
                  // @ts-ignore
                  op.xaxis.categories.push(k.substring(0, 33) + "...");
               } else {
                  // @ts-ignore
                  op.xaxis.categories.push(k);
               }
               // @ts-ignore
               sr[0].data.push(parseInt("" + prozent));
            });
            console.log("result", o, op);
            this.setState({ options: op, series: sr });
         }
      );
   }

   render() {
      const { classes, history } = this.props;

      return (
         <React.Fragment>
            <div className={classes.root}>
               <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center">
                  <Grid item xs={12}>
                     <Typography align="center" variant="h3">
                        {this.state.question}
                     </Typography>
                  </Grid>
                  <Grid item xs={12}>
                     <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        width="1600"
                        height="800"
                     />
                  </Grid>
               </Grid>
            </div>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(StatsBar));
