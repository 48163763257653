import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
   createStyles({
      changebackground: {
         top: 0,
         left: 0,
         position: "fixed",
         width: "100%",
         height: "100%",
         zIndex: -100,
         backgroundColor: theme.palette.background.default
      }
   });

interface IProps extends WithStyles<typeof styles> {}

interface IStates {}

class Changebg extends React.Component<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {};
   }
   render() {
      const { classes } = this.props;
      return <div className={classes.changebackground}></div>;
   }
}

export default withStyles(styles)(Changebg);
