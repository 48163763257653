import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "./lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
   Button,
   ButtonGroup,
   Grid,
   Hidden,
   Typography
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Menu from "./Menu";
import { Stream } from "./lib/Types";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FiberManualRecordOutlinedIcon from "@material-ui/icons/FiberManualRecordOutlined";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(0),
         width: "100%"
      },
      headerImg: {
         width: "100%",
         objectFit: "contain"
      },
      header: {
         marginBottom: theme.spacing(0)
      },
      htag: {
         textAlign: "right",
         width: "100%",
         paddingRight: theme.spacing(2)
      },
      list: {
         width: 320
      },
      menuButton: {
         marginRight: theme.spacing(2)
      },
      title: {
         display: "block"
      },
      streamButtons: {
         width: "100%"
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   currentStream: Stream;
   changeStream: (s: Stream) => void;
}

interface IStates {
   menuInView: boolean;
}

class Frame extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = { menuInView: false };
   }

   toggleMenu(event: React.KeyboardEvent | React.MouseEvent) {
      if (
         event &&
         event.type === "keydown" &&
         ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
      ) {
         return;
      }
      this.setState({ menuInView: !this.state.menuInView });
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      const { classes, children, currentStream } = this.props;

      return (
         <React.Fragment>
            <Grid
               className={classes.root}
               container
               direction="row"
               justify="center"
               alignItems="center"
               spacing={0}>
               <Grid item xs={12}>
                  {currentStream.showHeader ? (
                     <Grid container className={classes.header}>
                        <Grid item xs={12}>
                           <img
                              alt={currentStream.headerImage}
                              className={classes.headerImg}
                              src={currentStream.headerImage}
                           />
                        </Grid>
                     </Grid>
                  ) : (
                     <React.Fragment></React.Fragment>
                  )}
                  {currentStream.showNavbar ? (
                     <Grid item xs={12}>
                        <AppBar position="static">
                           <Toolbar>
                              <Grid container justifyContent="flex-start">
                                 <IconButton
                                    onClick={(e) => this.toggleMenu(e)}
                                    edge="start"
                                    className={classes.menuButton}
                                    color="inherit"
                                    aria-label="open drawer">
                                    <MenuIcon />
                                 </IconButton>
                                 <SwipeableDrawer
                                    anchor="left"
                                    open={this.state.menuInView}
                                    onClose={() =>
                                       this.setState({ menuInView: false })
                                    }
                                    onOpen={() =>
                                       this.setState({ menuInView: true })
                                    }>
                                    <div
                                       className={classes.list}
                                       role="presentation"
                                       onClick={() =>
                                          this.setState({ menuInView: false })
                                       }
                                       onKeyDown={() =>
                                          this.setState({ menuInView: false })
                                       }>
                                       <Menu
                                          currentStream={currentStream}
                                          changeStream={this.props.changeStream}
                                       />
                                    </div>
                                 </SwipeableDrawer>
                                 {currentStream.alternative.map((s) => {
                                    return (
                                       <Button
                                          startIcon={
                                             s.id === currentStream.id ? (
                                                <FiberManualRecordIcon />
                                             ) : (
                                                <FiberManualRecordOutlinedIcon />
                                             )
                                          }
                                          color="inherit"
                                          key={s.id}
                                          onClick={() => {
                                             this.props.changeStream(s);
                                          }}>
                                          {s.title}
                                       </Button>
                                    );
                                 })}
                              </Grid>
                              <Hidden smDown>
                                 <Grid container justifyContent="flex-end">
                                    <Typography
                                       className={classes.htag}
                                       variant="h6"
                                       color="inherit">
                                       <strong>{currentStream.navText}</strong>
                                    </Typography>
                                 </Grid>
                              </Hidden>
                           </Toolbar>
                           <Hidden mdUp>
                              <Grid
                                 container
                                 style={{
                                    paddingLeft: 24,
                                    paddingRight: 24,
                                    paddingBottom: 16
                                 }}>
                                 <Typography
                                    variant="h6"
                                    color="inherit"
                                    style={{ width: "100%" }}>
                                    <strong>{currentStream.navText}</strong>
                                 </Typography>
                              </Grid>
                           </Hidden>
                        </AppBar>
                     </Grid>
                  ) : (
                     <React.Fragment></React.Fragment>
                  )}
                  <Grid item xs={12}>
                     {children}
                  </Grid>

                  {currentStream.showFooter ? (
                     <Grid container className={classes.header}>
                        <Grid item xs={12}>
                           <img
                              alt={currentStream.footerImage}
                              className={classes.headerImg}
                              src={currentStream.footerImage}
                           />
                        </Grid>
                     </Grid>
                  ) : (
                     <React.Fragment></React.Fragment>
                  )}
               </Grid>
            </Grid>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(Frame));
