import { ThemeOptions } from "@material-ui/core/styles/createTheme";

export const lightThemeValues: ThemeOptions = {
   palette: {
      type: "light",
      background: {
         paper: "#ffffff",
         default: "#f0f0f0"
      }
   },
   overrides: {
      MuiAppBar: {
         colorPrimary: {
            backgroundColor: "#eee",
            color: "#444"
         }
      },
      MuiStepIcon: {
         root: {
            "&$completed": {
               color: "green"
            },
            "&$active": {
               //color: "blue",
            }
         },
         active: {},
         completed: {}
      }
   }
};
