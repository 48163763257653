import React from "react";
import clsx from "clsx";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import {
   Avatar,
   Button,
   Collapse,
   Container,
   Divider,
   FormControl,
   Grid,
   IconButton,
   InputAdornment,
   InputLabel,
   OutlinedInput,
   Paper,
   Select,
   Typography
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { Alert } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { Helper } from "../lib/Helper";
import { __Post } from "../lib/Rest";
import { emptyCallBack, funcSuccess, Stream, User } from "../lib/Types";
import Session from "../lib/Session";
import { i18n } from "../lib/i18n";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      paper: {
         paddingTop: theme.spacing(0),
         paddingBottom: theme.spacing(0),
         display: "flex",
         flexDirection: "column",
         alignItems: "center"
      },
      avatar: {
         margin: theme.spacing(1),
         backgroundColor: theme.palette.secondary.main
      },
      form: {
         width: "100%", // Fix IE 11 issue.
         marginTop: theme.spacing(1)
      },
      submit: {
         margin: theme.spacing(3, 0, 2)
      },
      margin: {
         margin: theme.spacing(1)
      },

      marginTop: {
         marginTop: theme.spacing(7)
      },
      withoutLabel: {
         marginTop: theme.spacing(3)
      },
      textField: {
         width: "100%"
      },
      backdrop: {
         zIndex: theme.zIndex.drawer + 1,
         color: "#fff"
      }
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   onSuccess: string | emptyCallBack;
   checkPassword: boolean;
   currentStream: Stream;
}

interface IStates {
   email: string;
   name: string;
   vorname: string;
   titel: string;
   anrede: string;
   showAlert: boolean;
   institution: string;
   password: string;
   showPassword: boolean;
   nameUsed: boolean;
}

class AnnoLogin extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);

      this.state = {
         email: this.getPreselect("__email", ""),
         name: this.getPreselect("__name", ""),
         vorname: this.getPreselect("__vorname", ""),
         titel: this.getPreselect("__titel", ""),
         anrede: this.getPreselect("__anrede", "Frau"),
         institution: this.getPreselect("__institution", ""),
         password: "",
         showAlert: false,
         nameUsed: false,
         showPassword: false
      };
   }
   getPreselect(name: string, def: string): string {
      let tmp = sessionStorage.getItem(this.props.currentStream.event + name);
      return tmp !== null ? tmp : def;
   }

   setPreselect(name: string, value: string): void {
      sessionStorage.setItem(this.props.currentStream.event + name, value);
   }

   tryLogin() {
      __Post(
         "registerlogin",
         {
            pass: this.state.password,
            email: this.state.email,
            institution: this.state.institution,
            anrede: this.state.anrede,
            titel: this.state.titel,
            vorname: this.state.vorname,
            name: this.state.name
         },
         (res: Array<User>) => {
            if (res.length === 0) {
               this.setState({ showAlert: true }, () => {
                  setTimeout(() => this.setState({ showAlert: false }), 5000);
               });
            } else if (res.length === 1) {
               let u: User = res[0];
               Session.getInstance().setUser(u);
               if (typeof this.props.onSuccess === "string") {
                  this.props.history.replace(this.props.onSuccess);
               } else {
                  this.props.onSuccess();
               }
            }
         }
      );
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      const { classes } = this.props;
      const _email: boolean = Helper.validatorEmail(this.state.email);
      const _name: boolean = true;
      const _vorname: boolean = true;
      const _title: boolean = true;
      const _institution: boolean = true;
      const _password: boolean = this.props.checkPassword
         ? Helper.validatorMin(this.state.password, 4)
         : true;

      return (
         <React.Fragment>
            <Grid container spacing={2}>
               <Grid item xs={12}>
                  <Paper
                     elevation={1}
                     variant="elevation"
                     className={classes.paper}>
                     <Container
                        component="div"
                        maxWidth="xs"
                        className={classes.paper}>
                        <Avatar className={classes.avatar}>
                           <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                           {i18n.get("Anmeldung")}
                        </Typography>

                        <FormControl
                           className={clsx(classes.margin, classes.textField)}
                           variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-email">
                              {i18n.get("Email")}
                           </InputLabel>
                           <OutlinedInput
                              error={!_email}
                              id="outlined-adornment-email"
                              type={"text"}
                              value={this.state.email}
                              onChange={(e) => {
                                 this.setState(
                                    { email: e.target.value },
                                    () => {
                                       this.setPreselect(
                                          "__email",
                                          this.state.email
                                       );
                                    }
                                 );
                                 if (!this.state.nameUsed) {
                                    var pre = e.target.value.split("@");
                                    this.setState({ name: pre[0] });
                                 }
                              }}
                              labelWidth={50}
                           />
                        </FormControl>

                        <FormControl
                           className={clsx(classes.margin, classes.textField)}
                           variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-password">
                              {i18n.get("Passwort")}
                           </InputLabel>
                           <OutlinedInput
                              error={!_password}
                              id="outlined-adornment-password"
                              type={
                                 this.state.showPassword ? "text" : "password"
                              }
                              value={this.state.password}
                              onChange={(e) => {
                                 this.setState({ password: e.target.value });
                              }}
                              endAdornment={
                                 <InputAdornment position="end">
                                    <IconButton
                                       aria-label="Visi"
                                       onClick={() => {
                                          this.setState({
                                             showPassword:
                                                !this.state.showPassword
                                          });
                                       }}
                                       edge="end">
                                       {this.state.showPassword ? (
                                          <Visibility />
                                       ) : (
                                          <VisibilityOff />
                                       )}
                                    </IconButton>
                                 </InputAdornment>
                              }
                              labelWidth={70}
                           />
                        </FormControl>

                        <FormControl
                           className={clsx(
                              classes.margin,
                              classes.textField,
                              classes.marginTop
                           )}>
                           <InputLabel htmlFor="age-native-simple">
                              {i18n.get("Anrede")}
                           </InputLabel>
                           <Select
                              native
                              value={this.state.anrede}
                              onChange={(
                                 e: React.ChangeEvent<{
                                    name?: string;
                                    value: unknown;
                                 }>
                              ) => {
                                 this.setState(
                                    { anrede: "" + e.target.value },
                                    () => {
                                       this.setPreselect(
                                          "__anrede",
                                          this.state.anrede
                                       );
                                    }
                                 );
                              }}>
                              <option value={"Frau"}>{i18n.get("Frau")}</option>
                              <option value={"Herr"}>{i18n.get("Herr")}</option>
                              <option value={"Diverse"}>
                                 {i18n.get("Diverse")}
                              </option>
                           </Select>
                        </FormControl>

                        <FormControl
                           className={clsx(classes.margin, classes.textField)}
                           variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-titel">
                              {i18n.get("Titel")}
                           </InputLabel>
                           <OutlinedInput
                              error={!_title}
                              id="outlined-adornment-titel"
                              type={"text"}
                              value={this.state.titel}
                              onChange={(e) => {
                                 this.setState(
                                    { titel: e.target.value },
                                    () => {
                                       this.setPreselect(
                                          "__titel",
                                          this.state.titel
                                       );
                                    }
                                 );
                              }}
                              labelWidth={50}
                           />
                        </FormControl>

                        <FormControl
                           className={clsx(classes.margin, classes.textField)}
                           variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-email">
                              {i18n.get("Institution")}
                           </InputLabel>
                           <OutlinedInput
                              error={!_institution}
                              id="outlined-adornment-institution"
                              type={"text"}
                              value={this.state.institution}
                              onChange={(e) => {
                                 this.setState(
                                    {
                                       institution: e.target.value
                                    },
                                    () => {
                                       this.setPreselect(
                                          "__institution",
                                          this.state.institution
                                       );
                                    }
                                 );
                              }}
                              labelWidth={50}
                           />
                        </FormControl>

                        <FormControl
                           className={clsx(classes.margin, classes.textField)}
                           variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-vname">
                              {i18n.get("Vorname")}
                           </InputLabel>
                           <OutlinedInput
                              error={!_vorname}
                              id="outlined-adornment-vname"
                              type={"text"}
                              value={this.state.vorname}
                              onChange={(e) => {
                                 this.setState(
                                    { vorname: e.target.value },
                                    () => {
                                       this.setPreselect(
                                          "__vorname",
                                          this.state.vorname
                                       );
                                    }
                                 );
                              }}
                              labelWidth={50}
                           />
                        </FormControl>

                        <FormControl
                           className={clsx(classes.margin, classes.textField)}
                           variant="outlined">
                           <InputLabel htmlFor="outlined-adornment-name">
                              {i18n.get("Name")}
                           </InputLabel>
                           <OutlinedInput
                              error={!_name}
                              id="outlined-adornment-name"
                              type={"text"}
                              value={this.state.name}
                              onChange={(e) => {
                                 this.setState(
                                    { name: e.target.value, nameUsed: true },
                                    () => {
                                       this.setPreselect(
                                          "__name",
                                          this.state.name
                                       );
                                    }
                                 );
                              }}
                              labelWidth={50}
                           />
                        </FormControl>

                        <Button
                           disabled={
                              !_email || !_name || !_vorname || !_password
                           }
                           onClick={() => this.tryLogin()}
                           type="submit"
                           fullWidth
                           variant="contained"
                           color="primary"
                           className={classes.submit}>
                           {i18n.get("Anmelden")}
                        </Button>

                        <Collapse
                           in={this.state.showAlert}
                           className={classes.textField}>
                           <Alert
                              className={classes.textField}
                              severity="error"
                              action={
                                 <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                       this.setState({ showAlert: false });
                                    }}>
                                    <CloseIcon fontSize="inherit" />
                                 </IconButton>
                              }>
                              {i18n.get("Anmelden fehlgeschlagen.")}
                           </Alert>
                        </Collapse>
                     </Container>
                  </Paper>
               </Grid>
            </Grid>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(AnnoLogin));
