import React from "react";
import {
   createStyles,
   Theme,
   withStyles,
   WithStyles
} from "@material-ui/core/styles";
import BaseComponent from "../lib/BaseComponent";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Stream } from "../lib/Types";
import { Box, Button, Typography } from "@material-ui/core";
import { i18n } from "../lib/i18n";
import Session from "../lib/Session";

const styles = (theme: Theme) =>
   createStyles({
      root: {
         padding: theme.spacing(2)
      },
      abstimmen: {}
   });

interface IProps extends WithStyles<typeof styles>, RouteComponentProps<any> {
   link: string;
   currentStream: Stream;
}

interface IStates {
   accept: boolean;
}

class Disclaimer extends BaseComponent<IProps, IStates> {
   constructor(props: IProps) {
      super(props);
      this.state = {
         accept: false
      };
   }

   componentDidMount() {}

   componentWillUnmount() {}

   render() {
      const { link, currentStream, classes, history } = this.props;
      return (
         <React.Fragment>
            <Box style={{ marginBottom: 50 }}>
               {this.state.accept ? (
                  <a href={link} target="_blank">
                     {link}
                  </a>
               ) : (
                  <>
                     <Typography variant="body1">
                        <div
                           dangerouslySetInnerHTML={{
                              __html: i18n.get("DISCLAIMER_TEXT")
                           }}></div>
                     </Typography>
                     <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => {
                           this.setState({ accept: true });
                        }}>
                        {i18n.get("DISCLAIMER_BUTTON")}
                     </Button>
                  </>
               )}
            </Box>
         </React.Fragment>
      );
   }
}

export default withStyles(styles)(withRouter(Disclaimer));
