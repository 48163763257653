import { i18n } from "./i18n";

export const Helper: any = {
   baseUrl: "https://streaming.multicaster.eu/lib/",
   webSocket: "wss://streaming.multicaster.eu/sock/",
   // baseUrl: "https://www.ganztagskongress.de/lib/",
   // webSocket: "wss://www.ganztagskongress.de/sock/",

   clientInfo: function () {
      return (
         "TZ:" +
         new Date().getTimezoneOffset() / 60 +
         " [" +
         navigator.language +
         "] : " +
         window.location.href +
         " -> (" +
         navigator.userAgent +
         ")"
      );
   },

   timeSince: function (date: Date) {
      let seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);
      let interval = seconds / 31536000;
      if (interval > 1) {
         return Math.floor(interval) + " " + i18n.get("years ago");
      }
      interval = seconds / 2592000;
      if (interval > 1) {
         return Math.floor(interval) + " " + i18n.get("months ago");
      }
      interval = seconds / 86400;
      if (interval > 1) {
         return Math.floor(interval) + " " + i18n.get("days ago");
      }
      interval = seconds / 3600;
      if (interval > 1) {
         return Math.floor(interval) + " " + i18n.get("hours ago");
      }
      interval = seconds / 60;
      if (interval > 1) {
         return Math.floor(interval) + " " + i18n.get("minutes ago");
      }
      return Math.floor(seconds) + " " + i18n.get("seconds ago");
   },

   encodeURL: function (str: string) {
      str = str.replaceAll(" ", "-");
      return encodeURI(str);
   },

   validatorEmail: function (value: string = "") {
      if (value === undefined || value === "") {
         return false;
      }
      var x = /\S+@\S+\.\S+/;
      return x.test(value);
   },
   validatorPassword: function (value: string | undefined) {
      // var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&]){8,}/;
      // return re.test(value);
      return value !== undefined && value !== "" && value.length > 6;
   },
   validatorIsEmpty: function (value: string | undefined) {
      return value !== undefined && value !== "";
   },
   validatorMin: function (value: string | undefined, anz: number = 3) {
      return value !== undefined && value !== "" && value.length >= anz;
   },
   validatorEmptyIsValid: function (value: string | undefined) {
      return true;
   },
   nullAsEmpty(val: any) {
      if (val === undefined || val === null) {
         return "";
      }
      return val;
   }
};
