import { User } from "./Types";

export default class Session {
   private static instance: Session;
   private user: User = this.getEmptyUser();

   private constructor() {}

   public static getInstance(): Session {
      if (!Session.instance) {
         Session.instance = new Session();
      }
      return Session.instance;
   }

   public getEmptyUser(): User {
      return {
         id: -1,
         email: "",
         name: "",
         vorname: "",
         titel: "",
         anrede: "",
         password: "",
         institution: ""
      };
   }

   public hasValidUser(): boolean {
      return this.user.id > 0;
   }

   public hasValidEventUser(eventName: string): boolean {
      return this.user.email.startsWith(eventName);
   }

   public getUser(): User {
      return this.user;
   }

   public setUser(user: User) {
      this.user = user;
   }

   public getUserName() {
      if (this.hasValidUser()) {
         return (
            this.user.anrede +
            " " +
            this.user.titel +
            " " +
            this.user.vorname +
            " " +
            this.user.name
         );
      } else {
         return "unkown";
      }
   }
}
